import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import CheckboxSelectField from '@/components/controlled-fields/CheckboxSelectField';
import SwitchField from '@/components/controlled-fields/SwitchField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import DividerTitle from '@/components/DividerTitle';
import TabPanelForm from '@/components/TabPanelForm';

import ElementBiodiversiteTable from '@/features/shared/element-biodiversite/ElementBiodiversiteTable';

import Grid from '@mui/material/Grid';

export default function EnjeuxSocioEnvTab({
  form,
  tabValue,
  choices,
  propriete,
  elementsBiodiversiteList,
}) {
  const { control } = form;

  const frequentationPublic = useWatch({
    control,
    name: 'propriete.frequentation_public',
  });

  return (
    <TabPanelForm currentValue={tabValue} index={2}>
      {propriete && (
        <form>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12}>
              <DividerTitle text={'Fréquentation par le public'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <SwitchField
                control={control}
                name='propriete.frequentation_public'
                label='Fréquentation public'
              />
            </Grid>
            {frequentationPublic && (
              <Grid item xs={12} sm={12} md={4}>
                <MultilineTextField
                  control={control}
                  name='propriete.importance_frequentation'
                  label='Importance  et nature de la fréquentation'
                />
              </Grid>
            )}
            <Grid item xs={12} mt={1}>
              <DividerTitle text={'Zonages identifiés'} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CheckboxSelectField
                control={control}
                name='propriete.zonages_identifies_list'
                baseId='zonage-constate'
                label='Zonages identifiés'
                values={choices?.zonages_identifies_types}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <MultilineTextField
                control={control}
                name='propriete.zonages_identifies_commentaire'
                label='Autre(s) zonage(s)/ commentaires'
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <DividerTitle text={'Biodiversité'} />
            </Grid>
            <Grid item xs={10} sm={8}>
              <ElementBiodiversiteTable items={elementsBiodiversiteList} />
            </Grid>
          </Grid>
        </form>
      )}
    </TabPanelForm>
  );
}

EnjeuxSocioEnvTab.propTypes = {
  form: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
  choices: PropTypes.object,
  propriete: PropTypes.object,
  elementsBiodiversiteList: PropTypes.array,
};
