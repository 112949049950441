import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useChoices from '@/hooks/useChoices';
import useFormItem from '@/hooks/useFormItem';

import FormSubmitButtons from '@/features/shared/FormSubmitButtons';
import InformationsGeneralesTab from '@/features/item-form/propriete/InformationsGeneralesTab';

import { setIsLoading } from '@/slices/isLoadingSlice';
import { addItem, patchItem } from '@/slices/itemSlice';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import EnjeuxEconomiquesTab from './EnjeuxEconomiquesTab';
import EnjeuxEnvironnementauxTab from './EnjeuxEnvironnementauxTab';
import EnjeuxSociauxTab from './EnjeuxSociauxTab';
import EquilibreForetGibierTab from './EquilibreForetGibierTab';
import GestionTab from './GestionTab';

export default function ProprieteForm({ item }) {
  const itemType = 'proprietes';

  const choices = useChoices(itemType);

  const { onCancel } = useFormItem(item.id, itemType);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fields1 = [
    'id',
    'nom_commun',
    'adresse',
    'commune',
    'code_postal',
    'departement',
    'surface_totale_propriete',
    'existence_dgd',
    'proprietaires_list',
    'catalogue_station',
    'catalogue_station_detail',
    'gestionnaire_preexistant',
    'gestionnaire',
    'droits_usage_servitudes_divers',
    'cmf_neosylva',
  ];

  const fields2 = [
    'objectifs_gestion_list',
    'historique_gestion',
    'certification_forestiere',
  ];

  const fields3 = [
    'acces_massif',
    'acces_grumier',
    'nb_place_depot',
    'besoin_acces_supplementaire',
    'historique_gestion',
    'description_filiere_bois',
    'charges',
    'ressources',
    'equipement_defense_incendie',
    'enjeux_economiques_gestion_forestiere',
  ];

  const fields4 = [
    'frequentation_public',
    'mesure_accueil_public',
    'mesure_reduction_frequentation',
    'balisage_particulier',
    'convention_ouverture_publique',
    'importance_frequentation',
    'impact_frequentation_gestion',
  ];

  const fields5 = ['zonages_identifies_list', 'zonages_identifies_commentaire'];

  const fields6 = [
    'acca',
    'societe_chasse',
    'reservataire',
    'plan_chasse',
    'degats_constates',
    'natures_degats_list',
    'commentaire_chasse_pression_gibier',
    'identite_detenteur_droit_chasse',
  ];

  const getDefaultValues = (fieldsList) =>
    Object.keys(item)
      .filter((key) => fieldsList.includes(key))
      .reduce((result, current) => {
        result[current] = item[current];
        return result;
      }, {});

  const form1 = useForm({
    defaultValues: getDefaultValues(fields1),
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    getValues: getValues1,
    formState: { isValid: isValid1 },
  } = form1;

  const form2 = useForm({
    defaultValues: getDefaultValues(fields2),
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const { getValues: getValues2 } = form2;

  const form3 = useForm({
    defaultValues: getDefaultValues(fields3),
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const { getValues: getValues3 } =form3;
  
  const form4 = useForm({
    defaultValues: getDefaultValues(fields4),
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const { getValues: getValues4 } = form4;

  const form5 = useForm({
    defaultValues: getDefaultValues(fields5),
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const { getValues: getValues5 } = form5;

  const form6 = useForm({
    defaultValues: getDefaultValues(fields6),
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const { getValues: getValues6 } =  form6;

  const onSubmit = () => {
    const formValues = {
      ...getValues1(),
      ...getValues2(),
      ...getValues3(),
      ...getValues4(),
      ...getValues5(),
      ...getValues6(),
    };

    let actionPromise;

    if (item.id) {
      actionPromise = dispatch(
        patchItem({ data: formValues, itemId: item.id, itemType })
      );
    } else {
      actionPromise = dispatch(addItem({ data: formValues, itemType }));
    }

    actionPromise
      .then(({ payload }) => {
        if (payload) {
          navigate('/proprietes');
        }
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        {item.id ? 'Édition' : 'Création'} proprieté
      </Typography>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant='scrollable'
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab label='Informations générales' />
        <Tab label='Gestion' />
        <Tab label='Enjeux économiques' />
        <Tab label='Enjeux sociaux' />
        <Tab label='Enjeux environnementaux' />
        <Tab label='Équilibre forêt-gibier' />
      </Tabs>
      <InformationsGeneralesTab
        form={form1}
        tabValue={tabValue}
        choices={choices}
        item={item}
      />
      <GestionTab form={form2} tabValue={tabValue} choices={choices} />
      <EnjeuxEconomiquesTab form={form3} tabValue={tabValue} choices={choices} />
      <EnjeuxSociauxTab form={form4} tabValue={tabValue} />
      <EnjeuxEnvironnementauxTab form={form5} tabValue={tabValue} choices={choices}/>
      <EquilibreForetGibierTab form={form6} tabValue={tabValue} choices={choices}/>
      <FormSubmitButtons
        onSubmit={onSubmit}
        submitLabel={item.id ? 'Éditer' : 'Créer'}
        disabledSubmit={!isValid1}
        onCancel={onCancel}
      />
    </Box>
  );
}

ProprieteForm.propTypes = {
  item: PropTypes.object.isRequired,
};
