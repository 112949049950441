import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function Users() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'users';

  const itemsList = useItems(itemType);

  const handleFormRedirect = () => {
    navigate('/user');
  };

  const handleEditItem = (itemId) => {
    navigate(`/user/${itemId}`);
  };

  const handleDeleteItem = async (itemId, itemName) => {
    const result = await open({
      title: 'Suppression',
      content: `Confirmer la suppression de ${itemName} ?`,
    });

    if (result === 'confirm') {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  return (
    <>
      {itemsList && (
        <Container component='main' maxWidth='lg'>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <Grid container>
              <Grid item xs={12} sm='auto'>
                <Button
                  variant='contained'
                  onClick={handleFormRedirect}
                  fullWidth
                >
                  Ajouter un utilisateur
                </Button>
              </Grid>
            </Grid>

            <TableContainer component={Paper} elevation={3} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Identifiant</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Création</TableCell>
                    <TableCell>Dernière modification</TableCell>
                    <TableCell>Rôle</TableCell>
                    <TableCell>Dernière connexion</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemsList.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{row.username}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>
                        {fromISODateTime(row.creation_datetime)}
                      </TableCell>
                      <TableCell>
                        {fromISODateTime(row.last_modification_datetime)}
                      </TableCell>
                      <TableCell>{row.role?.verbose_name}</TableCell>
                      <TableCell>
                        {fromISODateTime(row.last_login_datetime)}
                      </TableCell>
                      <TableCell>
                        <Stack direction='row'>
                          <IconButton onClick={() => handleEditItem(row.id)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleDeleteItem(row.id, row.username)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      )}
    </>
  );
}
