import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function ProprieteAutocompleteField({
  propriete,
  setPropriete,
  proprietesList,
  disabled,
}) {

  const [error, setError] = useState(false);

  return (
    <Autocomplete
      required
      onChange={(_, item) => setPropriete(item)}
      loading={!proprietesList}
      options={proprietesList || []}
      value={propriete}
      getOptionLabel={(item) => item?.nom_commun || ''}
      onInputChange={(_, value) => setError(!value)}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label='Propriété'
          value={propriete || ''}
          required
          error={error}
          helperText={error ? 'Propriété requise.' : null}
        />
      )}
    />

  );
}

ProprieteAutocompleteField.propTypes = {
  propriete: PropTypes.object,
  setPropriete: PropTypes.func.isRequired,
  proprietesList: PropTypes.array,
  disabled: PropTypes.bool,
};
