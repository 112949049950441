import React from 'react';

import PropTypes from 'prop-types';

import TabPanelForm from '@/components/TabPanelForm';

import Grid from '@mui/material/Grid';

export default function SuiviEconomiqueTab({ tabValue }) {
  return (
    <TabPanelForm currentValue={tabValue} index={3}>
      <form>
        <Grid container spacing={2}>

        </Grid>
      </form>
    </TabPanelForm>
  );
}

SuiviEconomiqueTab.propTypes = {
  tabValue: PropTypes.number,
};