import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

import BaseDataGrid from './BaseDataGrid';
import { setFilteredItems } from '@/slices/itemSlice';

export default function CRUDDataGrid({
  modal,
  itemsList,
  relatedItemsList,
  columns,
  itemType,
  addItemLabel,
  title,
  onDuplicate,
  disabledEdit,
  ...props
}) {
  const dispatch = useDispatch();

  const [editItem, setEditItem] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const rows = useMemo(() => {
    return itemsList?.map((item, idx) => {
      const row = { ...item };
      row.rowId = idx + 1;
      return row;
    });
  }, [itemsList]);

  const updatedColumns = useMemo(() => {
    if (disabledEdit) {
      return columns;
    }

    return [
      ...columns,
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: onDuplicate ? 110 : 80,
        getActions: (params) => {
          const actions = [];
          if (!params.row.disabledEdit) {
            actions.push(
              <GridActionsCellItem
                key={params.id}
                icon={<EditIcon />}
                label='Éditer'
                onClick={() => showForm(params.row)}
              />,
              <GridActionsCellItem
                key={params.id}
                icon={<DeleteIcon />}
                label='Supprimer'
                onClick={() => handleDelete(params.id)}
              />
            );
          }

          if (onDuplicate) {
            actions.push(
              <GridActionsCellItem
                key={params.id}
                icon={<LibraryAddIcon />}
                label='Dupliquer'
                onClick={() => duplicate(params.row)}
              />
            );
          }
          return actions;
        },
      },
    ];
  }, [columns, disabledEdit, onDuplicate]);

  const duplicate = useCallback(
    (rowToDuplicate) => {
      const newRow = onDuplicate(rowToDuplicate);

      let updatedItemsList = [...rows, newRow];
      if (relatedItemsList) {
        updatedItemsList = [...relatedItemsList, ...updatedItemsList];
      }

      dispatch(
        setFilteredItems({
          itemsList: updatedItemsList,
          itemType,
        })
      );
    },
    [rows, relatedItemsList, onDuplicate, dispatch, itemType]
  );

  const handleDelete = useCallback(
    (rowId) => {
      let itemsToKeepList = rows.filter((row) => row.rowId !== rowId);

      if (relatedItemsList) {
        itemsToKeepList = [...relatedItemsList, ...itemsToKeepList];
      }

      dispatch(
        setFilteredItems({
          itemsList: itemsToKeepList,
          itemType,
        })
      );
    },
    [rows, relatedItemsList, dispatch, itemType]
  );

  const onFormSubmit = useCallback(
    (data) => {
      let updatedItemsList;
      if (!editItem) {
        updatedItemsList = [...rows, data];
      } else {
        updatedItemsList = rows.map((p) => (p.rowId === data.rowId ? data : p));
      }

      if (relatedItemsList) {
        updatedItemsList = [...relatedItemsList, ...updatedItemsList];
      }

      setEditItem(null);
      setOpenModal(false);

      dispatch(
        setFilteredItems({
          itemsList: updatedItemsList,
          itemType,
        })
      );
    },
    [rows, relatedItemsList, editItem, dispatch, itemType]
  );

  const showForm = useCallback((item) => {
    setEditItem(item);
    setOpenModal(true);
  }, []);

  const ModalForm = modal.component;
  const modalProps = modal.props || {};

  return (
    <>
      {title && (
        <Typography mb={1} variant='subtitle1' component='div'>
          {title} :
        </Typography>
      )}
      <Button
        size='small'
        variant='contained'
        disabled={disabledEdit}
        onClick={() => showForm()}
      >
        {addItemLabel}
      </Button>

      <BaseDataGrid
        boxProps={{
          sx: {
            my: 2,
          },
        }}
        rows={rows}
        columns={updatedColumns}
        getRowId={(row) => row.rowId}
        {...props}
      />
      <ModalForm
        open={openModal}
        setOpen={setOpenModal}
        onSubmit={onFormSubmit}
        editItem={editItem}
        {...modalProps}
      />
    </>
  );
}

CRUDDataGrid.propTypes = {
  modal: PropTypes.object,
  itemsList: PropTypes.array,
  relatedItemsList: PropTypes.array,
  columns: PropTypes.array.isRequired,
  itemType: PropTypes.string.isRequired,
  addItemLabel: PropTypes.string.isRequired,
  title: PropTypes.string,
  onDuplicate: PropTypes.func,
  disabledEdit: PropTypes.bool,
  headerClassName: PropTypes.string,
};
