import React from 'react';

import PropTypes from 'prop-types';

import CRUDDataGrid from '@/components/CRUDDataGrid';
import AccesOuvrageFormDialog from './AccesOuvrageFormDialog';

export default function AccesOuvrageTable({ items }) {
  const columns = [
    {
      field: 'code',
      headerName: 'Identifiant unique',
      width: 160,
      editable: false,
    },
    {
      field: 'nature',
      headerName: 'Nature',
      width: 220,
      editable: false,
      valueGetter: (value) => value.nom,
    },
    {
      field: 'preco_gestion',
      headerName: 'Préconisation de gestion',
      width: 220,
      editable: false,
    },
    {
      field: 'commentaire',
      headerName: 'Commentaire',
      width: 220,
      editable: false,
    },
  ];

  return (
    <CRUDDataGrid
      itemsList={items}
      columns={columns}
      itemType={'acces_ouvrages'}
      title={"Eléments d'accès ou d'ouvrages de la propriété"}
      addItemLabel={'Ajouter un élément'}
      modal={{ component: AccesOuvrageFormDialog, props: { items } }}
    />
  );
}

AccesOuvrageTable.propTypes = {
  items: PropTypes.array,
};
