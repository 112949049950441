import APIService from '@/APIService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessage } from './messageSlice';

const ONE_MINUTE = 1000 * 60;
const ONE_YEAR = ONE_MINUTE * 60 * 24 * 365;

export const fetchAllItems = createAsyncThunk(
  'items/all',
  async (itemType, { rejectWithValue }) => {
    const res = await APIService.get({
      url: itemType,
      onError: (response) => {
        rejectWithValue(response.data);
      },
    });

    return { itemType, itemsList: res.data };
  }
);

export const fetchFilteredItems = createAsyncThunk(
  'items/filtered',
  async ({ filter, itemType }, { rejectWithValue }) => {
    const res = await APIService.get({
      url: itemType,
      opts: { params: filter },
      onError: (response) => {
        rejectWithValue(response.data);
      },
    });

    return { itemType, itemsList: res.data };
  }
);

export const fetchAllChoices = createAsyncThunk(
  'items/choices',
  async (itemType, { rejectWithValue }) => {
    const res = await APIService.get({
      url: `${itemType}/choices`,
      opts: {
        id: `${itemType}/choices`,
      },
      cache: {
        ttl: itemType === 'essences' ? ONE_MINUTE : ONE_YEAR,
      },
      onError: (response) => {
        rejectWithValue(response.data);
      },
    });

    return { itemType, choices: res.data, cached: res.cached };
  }
);

export const patchItem = createAsyncThunk(
  'items/edit',
  async ({ data, itemId, itemType }, { dispatch, rejectWithValue }) => {
    const res = await APIService.patch({
      url: `${itemType}/${itemId}`,
      data: data,
      onError: (res) => {
        const message = res.data.detail || res.data.message;
        if (message) {
          dispatch(setMessage(message));
        }
        rejectWithValue(res.data);
      },
    });

    const resData = res?.data;

    const message = resData?.message;
    if (message) {
      dispatch(setMessage(message));
    }

    return resData;
  }
);

export const addItem = createAsyncThunk(
  'items/create',
  async ({ data, itemType }, { dispatch, rejectWithValue }) => {
    const res = await APIService.post({
      url: itemType,
      data: data,
      onError: (res) => {
        const message = res.data.detail || res.data.message;
        if (message) {
          dispatch(setMessage(message));
        }
        rejectWithValue(res.data);
      },
    });

    const resData = res?.data;

    const message = resData?.message;
    if (message) {
      dispatch(setMessage(message));
    }

    return resData;
  }
);

export const fetchItemById = createAsyncThunk(
  'items/',
  async ({ itemId, itemType }, { rejectWithValue }) => {
    const res = await APIService.get({
      url: `${itemType}/${itemId}`,
      onError: (response) => {
        rejectWithValue(response.data);
      },
    });

    return res.data;
  }
);

export const deleteItem = createAsyncThunk(
  'items/delete',
  async ({ itemId, itemType }, { dispatch, rejectWithValue }) => {
    const res = await APIService.delete({
      url: `${itemType}/${itemId}`,
      onError: (res) => {
        const message = res.data.detail || res.data.message;
        if (message) {
          dispatch(setMessage(message));
        }
        rejectWithValue(res.data);
      },
    });

    const message = res?.data?.message;
    if (message) {
      dispatch(setMessage(message));
    }

    return { itemId, itemType };
  }
);

const slice = createSlice({
  name: 'items',
  initialState: {
    pendingActions: 0,
    items: {
      certifications: null,
      constats_reprises_essences: null,
      contacts: null,
      contrats_etf: null,
      contrats_financeur: null,
      contrats_proprietaires: null,
      depenses: null,
      dgd: null,
      especes_plan_chasse: null,
      elements_biodiversite: null,
      peuplements_objectifs_essences: null,
      financeurs_carbone: null,
      gestionnaires_pro: null,
      interventions_passees: null,
      interventions: null,
      parcelles_cadastrales_pressenties_sous_contrat: null,
      parcelles_cadastrales_sous_contrat: null,
      proprietaires: null,
      proprietes: null,
      recettes: null,
      rendez_vous_annuels: null,
      responsables_etf: null,
      services_env: null,
      stocks_carbone: null,
      unites_gestion: null,
      users: null,
      stations: null,
      peuplements_initiaux: null,
      programmes_rd: null,
      essences: null,
      entreprises: null,
      departements_cmf: null,
      tableau_bord_cmf: null,
    },
    filteredItems: {
      parcelles_cadastrales_pressenties_sous_contrat: null,
      parcelles_cadastrales_sous_contrat: null,
      indivisaires: null,
      documents: null,
      especes_plan_chasse: null,
      acces_ouvrages: null,
      elements_biodiversite: null,
      stations: null,
      unites_gestion: null,
      interventions: null,
      peuplements_objectifs_essences: null,
      essences_peuplement_initial: null,
      certifications: null,
      dgd: null,
      proprietaires: null,
      proprietes: null,
      contrats_proprietaires: null,
    },
    filteredItemsToClear: [],
    currentItem: null,
    choices: {
      certifications: null,
      contacts: null,
      contrats_proprietaires: null,
      dgd: null,
      peuplements_objectifs_essences: null,
      indivisaires: null,
      interventions_passees: null,
      interventions: null,
      proprietaires: null,
      proprietes: null,
      rendez_vous_annuels: null,
      services_env: null,
      stocks_carbone: null,
      unites_gestion: null,
      users: null,
      stations: null,
      peuplements_initiaux: null,
      especes_plan_chasse: null,
      elements_biodiversite: null,
      nouveau_contact: null,
      creer_diagnostic_neosylva: null,
      diagnostic_gestionnaire: null,
      acces_ouvrages: null,
    },
  },
  reducers: {
    clearCurrentItem: (state) => {
      state.currentItem = null;
    },
    setEmptyCurrentItem: (state, { payload }) => {
      state.currentItem = payload;
    },
    setFilteredItems: (state, { payload: { itemType, itemsList } }) => {
      state.filteredItemsToClear.push(itemType);
      state.filteredItems[itemType] = itemsList;
    },
    clearFilteredItems: (state) => {
      state.filteredItemsToClear.forEach(itemType => {
        state.filteredItems[itemType] = null;
      });
      state.filteredItemsToClear = [];
    },
    clearItems: (state, { payload }) => {
      state.items[payload] = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAllChoices.fulfilled,
      (state, { payload: { itemType, choices, cached } }) => {
        if (!cached || !state.choices[itemType]) {
          state.choices[itemType] = choices;
        }
      }
    );
    builder.addCase(
      fetchAllItems.fulfilled,
      (state, { payload: { itemType, itemsList } }) => {
        state.items[itemType] = itemsList;
      }
    );
    builder.addCase(
      fetchFilteredItems.fulfilled,
      (state, { payload: { itemType, itemsList } }) => {
        state.filteredItemsToClear.push(itemType);
        state.filteredItems[itemType] = itemsList;
      }
    );
    builder.addCase(fetchItemById.fulfilled, (state, { payload }) => {
      state.currentItem = payload;
    });
    builder.addCase(
      deleteItem.fulfilled,
      (state, { payload: { itemType, itemId } }) => {
        state.items[itemType] = state.items[itemType]?.filter(
          (item) => item.id !== itemId
        );
      }
    );

    builder.addCase(fetchFilteredItems.pending, (state) => {
      state.pendingActions++;
    });
    builder.addMatcher(fetchFilteredItems.settled, (state) => {
      state.pendingActions--;
    });
  },
});

export const {
  clearCurrentItem,
  setEmptyCurrentItem,
  clearItems,
  setFilteredItems,
  clearFilteredItems,
} = slice.actions;

export default slice.reducer;
