import React from 'react';

import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useChoices from '@/hooks/useChoices';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import CSVExportButton from '@/components/CSVExportButton';
import StatefulDataGrid from '@/components/StatefulDataGrid';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function InterventionsPassees() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'interventions_passees';

  const choices = useChoices(itemType);

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/intervention-passee/${itemId}`);
  };

  const handleDeleteItem = async (itemId, interventionType) => {
    const result = await open({
      title: 'Suppression',
      content: `Confirmer la suppression de l'intervention passée ${interventionType} ?`,
    });

    if (result === 'confirm') {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(params.id, params.row.type.code)}
        />,
      ],
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: choices?.types.map((value) => `(${value.code}) ${value.nom}`),
      width: 220,
      editable: false,
      valueGetter: (value) =>
        `(${value.code}) ${value.nom}`,
    },
    {
      field: 'commentaire',
      headerName: 'Commentaire',
      width: 320,
      editable: false,
    },
    {
      field: 'annee_prevue',
      headerName: 'Anne prévue',
      width: 200,
      editable: false,
    },
    {
      field: 'nom_ancienne_parcelle',
      headerName: 'Nom ancienne parcelle',
      width: 200,
      editable: false,
    },
    {
      field: 'dgd',
      headerName: 'DGD',
      width: 260,
      valueGetter: (value) => value.type, // For filtering ...
      renderCell: ({ row }) => (
        <Link component={RouterLink} to={`/dgd/${row.dgd.id}`}>
          {row.dgd.type.code} - {row.dgd.numero}
        </Link>
      ),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/intervention-passee');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Interventions passées
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter une intervention passée
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <CSVExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <StatefulDataGrid columns={columns} rows={items} boxSx={{ my: 2 }} />
    </Box>
  );
}
