import React from 'react';

import { useNavigate } from 'react-router-dom';

import useChoices from '@/hooks/useChoices';
import useItems from '@/hooks/useItems';

import StatefulDataGrid from '@/components/StatefulDataGrid';
import XLSXExportButton from '@/components/XLSXExportButton';

import { fromISODateTime } from '@/utils/date';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function Essences() {
  const navigate = useNavigate();

  const itemType = 'essences';

  const choices = useChoices(itemType);

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/essence/${itemId}`);
  };

  const columns = [
    {
      field: 'nom_vernaculaire',
      headerName: 'Nom vernaculaire',
      width: 200,
      editable: false,
    },
    {
      field: 'nom_latin',
      headerName: 'Nom latin',
      width: 200,
      editable: false,
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: choices?.types.map((value) => value.nom),
      width: 160,
      editable: false,
      valueGetter: (value) => value?.nom,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 220,
      editable: false,
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
      ],
    },
  ];

  const handleFormRedirect = () => {
    navigate('/essence');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Essences
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter une essence
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <XLSXExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <StatefulDataGrid
        columns={columns}
        rows={items}
        defaultPageSize={50}
        boxSx={{ my: 2 }}
      />
    </Box>
  );
}
