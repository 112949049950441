import React from 'react';

import PropTypes from 'prop-types';

import StatefulDataGrid from '@/components/StatefulDataGrid';
import TabPanel from '@/components/TabPanel';

import NewProprieteButton from '@/features/tableau-bord-cmf/NewProprieteButton';

export default function ProprietesTab({
  tabValue,
  items,
  columns,
  ...props
}) {

  return (
    <TabPanel currentValue={tabValue} index={0}>
      <NewProprieteButton />
      <StatefulDataGrid
        boxSx={{ my: 2 }}
        columns={columns}
        rows={items}
        {... props }
      />
    </TabPanel >
  );
}

ProprietesTab.propTypes = {
  tabValue: PropTypes.number,
  items: PropTypes.array,
  columns: PropTypes.array,
};