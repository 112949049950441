import React from 'react';

import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import CellExpand from '@/components/CellExpand';
import CSVExportButton from '@/components/CSVExportButton';
import StatefulDataGrid from '@/components/StatefulDataGrid';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function ParcellesCadastrales() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'parcelles_cadastrales_pressenties_sous_contrat';

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/parcelle-cadastrale-pressentie-sous-contrat/${itemId}`);
  };

  const handleDeleteItem = async (itemId, section, numero) => {
    const result = await open({
      title: 'Suppression',
      content: `Confirmer la suppression de la parcelle ${section}-${numero} ?`,
    });

    if (result === 'confirm') {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const getNomCommune = (parcelleRow) =>
    parcelleRow.nom_commune
      ? `${parcelleRow.nom_commune} ${parcelleRow.code_commune}`
      : parcelleRow.code_commune;

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() =>
            handleDeleteItem(params.id, params.row.section, params.row.numero)
          }
        />,
      ],
    },
    {
      field: 'prefixe',
      headerName: 'Préfixe',
      width: 120,
      editable: false,
    },
    {
      field: 'section',
      headerName: 'Section',
      width: 120,
      editable: false,
    },
    {
      field: 'numero',
      headerName: 'Numéro',
      width: 120,
      editable: false,
    },
    {
      field: 'contenance',
      headerName: 'Surface cadastrale (ha)',
      width: 160,
      editable: false,
      valueFormatter: (value) => (value ? value.toFixed(4) : null),
    },
    {
      field: 'commune',
      headerName: 'Commune',
      width: 260,
      editable: false,
      renderCell: ({ row }) => <CellExpand value={getNomCommune(row)} />,
      valueGetter: (_, row) => getNomCommune(row),
    },
    {
      field: 'propriete',
      headerName: 'Propriété',
      width: 260,
      valueGetter: (value) => value.nom_commun, // For filtering ...
      renderCell: ({ row }) => (
        <Link component={RouterLink} to={`/propriete/${row.propriete.id}`}>
          {row.propriete.nom_commun}
        </Link>
      ),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/parcelle-cadastrale');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Parcelles cadastrales
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter une parcelle
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <CSVExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <StatefulDataGrid columns={columns} rows={items} boxSx={{ my: 2 }} />
    </Box>
  );
}
