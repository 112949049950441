import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const openDialogThunk = createAsyncThunk(
  'confirm',
  async (opts, { extra, dispatch }) => {
    const store = extra.store;

    dispatch(openDialog(opts));

    return new Promise((resolve) => {
      const unsubscribe = store.subscribe(() => {
        const state = store.getState();
        if (state.confirmationReducer.isConfirmed) {
          unsubscribe();
          resolve('confirm');
        } else if (state.confirmationReducer.isDeclined) {
          unsubscribe();
          resolve('decline');
        } else if (state.confirmationReducer.isAlternativeConfirmed) {
          unsubscribe();
          resolve('alternative');
        }
      });
    });
  }
);

export const confirmationSlice = createSlice({
  name: 'confirm',
  initialState: {
    title: '',
    confirmButton: '',
    content: '',
    isOpened: false,
    isDeclined: false,
    isConfirmed: false,
  },
  reducers: {
    confirmAction: (state) => {
      state.title = '';
      state.confirmButton = '';
      state.declineButton = '';
      state.alternativeButton = '';
      state.content = '';
      state.isOpened = false;
      state.isConfirmed = true;
    },
    declineAction: (state) => {
      state.title = '';
      state.confirmButton = '';
      state.declineButton = '';
      state.alternativeButton = '';
      state.content = '';
      state.isDeclined = true;
      state.isOpened = false;
    },
    alternativeConfirmAction: (state) => {
      state.title = '';
      state.confirmButton = '';
      state.declineButton = '';
      state.alternativeButton = '';
      state.content = '';
      state.isOpened = false;
      state.isAlternativeConfirmed = true;
    },
    openDialog: (state, { payload }) => {
      state.title = payload.title;
      state.confirmButton = payload.confirmButton || 'Confirmer';
      state.declineButton = payload.declineButton || 'Annuler';
      state.alternativeButton = payload.alternativeButton || '';
      state.content = payload.content;
      state.isOpened = true;
      state.isDeclined = false;
      state.isConfirmed = false;
      state.isAlternativeConfirmed = false;
    },
  },
});

export const {
  openDialog,
  confirmAction,
  alternativeConfirmAction,
  declineAction,
} = confirmationSlice.actions;

export default confirmationSlice.reducer;
