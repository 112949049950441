import React from 'react';

import PropTypes from 'prop-types';

import AutoCompleteSearchField from '@/components/AutoCompleteSearchField';

export default function ContactAutocompleteSearchField({
  contact,
  setContact,
  required,
  customFilter,
  disabled,
}) {
  return (
    <AutoCompleteSearchField
      label='Contact'
      required={required && 'Contact requis.'}
      itemState={[contact, setContact]}
      getOptionLabel={(item) => {
        let description = item?.nom;
        if (item?.prenom) {
          description = `${item.prenom} ${description}`;
        }
        return description || item || '';
      }}
      itemType='contacts'
      customFilter={customFilter}
      disabled={disabled}
    />
  );
}

ContactAutocompleteSearchField.propTypes = {
  contact: PropTypes.object,
  setContact: PropTypes.func.isRequired,
  required: PropTypes.bool,
  customFilter: PropTypes.func,
  disabled: PropTypes.bool,
};