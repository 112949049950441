import { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * Custom hook to navigate back in history.
 *
 * This hook provides a function to navigate back to the previous entry in the browser's history stack.
 * It uses the `useNavigate` hook from `react-router-dom` to perform the navigation.
 *
 * @param {string} [defaultPath] - The default path to navigate to if there is no previous location.
 * @returns {function} - A function that navigates back in history when called.
 *
 * @example
 * import useGoBackHistory from '@/hooks/useGoBackHistory';
 *
 * function MyComponent() {
 *   const goBack = useGoBackHistory('/default-path');
 *
 *   return (
 *     <button onClick={goBack}>Go Back</button>
 *   );
 * }
 */
export default function useGoBackHistory(defaultPath) {
  const navigate = useNavigate();
  const location = useLocation();
  const previousLocationRef = useRef(null);

  useEffect(() => {
    // Update the previous location only if it has changed
    if (previousLocationRef.current !== location) {
      previousLocationRef.current = location;
    }
  }, [location]);

  const goBack = useCallback(() => {
    const previousLocation = previousLocationRef.current;

    // Check if the previous location is the same as the current location
    if (previousLocation?.pathname === location?.pathname && defaultPath) {
      // Navigate to the default path if provided
      navigate(defaultPath);
    } else {
      // Navigate back in history
      navigate(-1);
    }
  }, [navigate, location, defaultPath]);

  return goBack;
}
