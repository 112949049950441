import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { toISODate } from '@/utils/date';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DatePickerField({
  control,
  name,
  label,
  required,
  disabled,
  disableFuture = false,
}) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DatePicker
          openTo='year'
          label={label}
          value={value ? new Date(value) : null}
          disableFuture={disableFuture}
          onChange={(val) => onChange(toISODate(val))}
          disabled={disabled}
          slotProps={{
            field: {
              clearable: !required,
            },
            textField: {
              error: !!error,
              fullWidth: true,
              helperText: error?.message,
              required: Boolean(required),
              inputProps: {
                role: 'input',
              }
            },
          }}
        />
      )}
    />
  );
}

DatePickerField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disableFuture: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
