import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import BaseDataGrid from '@/components/BaseDataGrid';

export default function OrientationsGestionTable({
  unitesGestionList,
  datagridSx,
}) {
  const columns = useMemo(
    () => [
      {
        field: 'nom',
        headerName: 'N° Unité de gestion',
        width: 140,
        editable: false,
      },
      {
        field: 'surface_reboisement_plantation_plein',
        headerName:
          'Surface prévisionnelle de reboisement par plantation en plein (ha)',
        width: 320,
        editable: false,
      },
      {
        field: 'surface_boisement_plantation_plein',
        headerName:
          'Surface prévisionnelle de boisement par plantation en plein (ha)',
        width: 320,
        editable: false,
      },
      {
        field: 'surface_amelioration_sylvicole_fr',
        headerName: 'Futaie régulière',
        width: 180,
        editable: false,
      },
      {
        field: 'surface_amelioration_sylvicole_fi',
        headerName: 'Futaie irrégulière',
        width: 180,
        editable: false,
      },
      {
        field: 'surface_dynamique_naturelle',
        headerName: 'Surface prévisionnelle laisée en dynamique naturelle (ha)',
        width: 320,
        editable: false,
      },
      {
        field: 'surface_non_forestiere',
        headerName: 'Surface non forestière (ha)',
        width: 260,
        editable: false,
      },
    ],
    []
  );

  const columnGroupingModel = useMemo(
    () => [
      {
        groupId: 'surface_amelioration_sylvicole',
        headerName: 'Surface prévisionnelle en amélioration sylvicole (ha)',
        children: [
          {
            field: 'surface_amelioration_sylvicole_fr',
          },
          {
            field: 'surface_amelioration_sylvicole_fi',
          },
        ],
      },
    ],
    []
  );

  const rows = useMemo(() => {
    const baseRows =
      unitesGestionList?.map((uniteGestion, id) => ({
        nom: uniteGestion.nom,
        id: id + 1,
      })) || [];

    if (baseRows.length > 0) {
      const totalRow = {
        nom: 'Total',
        id: 'total',
      };
      baseRows.push(totalRow);
    }

    return baseRows;
  }, [unitesGestionList]);

  return (
    <BaseDataGrid
      columns={columns}
      rows={rows}
      sx={datagridSx}
      alternateRowColor
      columnGroupingModel={columnGroupingModel}
    />
  );
}

OrientationsGestionTable.propTypes = {
  unitesGestionList: PropTypes.array,
  datagridSx: PropTypes.object.isRequired,
};
