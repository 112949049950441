import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import useConditionalRequired from '@/hooks/useConditionalRequired';
import useFetchFilteredItems from '@/hooks/useFetchFilteredItems';
import useSetFormValues from '@/hooks/useSetFormValues';

import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';

import SelectField from '@/components/controlled-fields/SelectField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import CodePostalTextField from '@/components/controlled-fields/text-fields/CodePostalTextField';
import MailTextField from '@/components/controlled-fields/text-fields/MailTextField';
import UpperCaseTextField from '@/components/controlled-fields/text-fields/UpperCaseTextField';
import TabPanelForm from '@/components/TabPanelForm';
import DividerTitle from '@/components/DividerTitle';

import ProprietaireTable from '@/features/shared/proprietaire/ProprietaireTable';
import AdresseField from '@/features/shared/AdresseField';
import DepartementField from '@/features/shared/DepartementField';
import ContratProprietaireAutocompleteField from '@/features/shared/ContratProprietaireAutocompleteField';
import IndivisaireReferentSelect from '@/features/shared/indivisaire/IndivisaireReferentSelect';
import IndivisaireTable from '@/features/shared/indivisaire/IndivisaireTable';
import ReferentTechniqueFormDialog from '@/features/shared/referent-technique/ReferentTechniqueFormDialog';

import ProprietaireAutocompleteField from './ProprietaireAutocompleteField';

import { setFilteredItems } from '@/slices/itemSlice';

export default function RenseignementProprietaireTab({
  edition,
  tabValue,
  isIndivisaireState,
  contratProprietaireState,
  proprietaireState,
  isDifferentReferentState,
  referentIsIndivisaireState,
  clearIndivisaireReferent,
  formIndivisaireReferent,
  emptyContact,
  proprietaireFields,
  contratId,
  form,
  choices,
  proprietairesList,
}) {
  const dispatch = useDispatch();

  const fetchFilteredItems = useFetchFilteredItems();

  const { isIndivisaire, setIsIndivisaire } = isIndivisaireState;
  const { contratProprietaire, setContratProprietaire } =
    contratProprietaireState;
  const { proprietaire, setProprietaire } = proprietaireState;
  const { isDifferentReferent, setIsDifferentReferent } =
    isDifferentReferentState;
  const { referentIsIndivisaire, setReferentIsIndivisaire } =
    referentIsIndivisaireState;

  const { control, setValue, trigger, getValues } = form;
  const {
    control: indivisaireReferentControl,
    setValue: setIndivisaireReferentSelectValue,
  } = formIndivisaireReferent;

  const indivisairesList = useSelector(
    (store) => store.itemReducer.filteredItems.indivisaires
  );

  const referentTechniqueWatch = useWatch({
    control,
    name: 'contrat_proprietaire.referent_technique',
  });

  const indivisaireReferentSelectWatch = useWatch({
    control: indivisaireReferentControl,
    name: 'indivisaire',
  });

  const personnaliteJuridique = useWatch({
    control,
    name: 'proprietaire.personnalite_juridique.code',
  });

  const conditionalRequiredFields = [
    ['proprietaire.contact.telephone_portable'],
    ['proprietaire.contact.email'],
  ];

  const requiredMapper = useConditionalRequired(
    conditionalRequiredFields,
    control,
    trigger
  );

  const codePostalWatch = useWatch({
    control,
    name: 'proprietaire.contact.code_postal',
  });

  const setFormValues = useSetFormValues();

  const contratsProprietaires =
    edition &&
    useSelector(
      (store) => store.itemReducer.filteredItems.contrats_proprietaires
    );

  useEffect(() => {
    if (!proprietaire) {
      return;
    }

    fetchFilteredItems({
      filter: { proprietaire_id: proprietaire.id },
      itemTypes: ['proprietes'],
    });

    setFormValues(proprietaireFields, proprietaire, 'proprietaire');

    setIsIndivisaire(proprietaire.indivisaire !== null);

    if (proprietaire.indivisaire) {
      const indivision = proprietaire.indivisaire.indivision;

      fetchFilteredItems({
        filter: { indivision_id: indivision.id },
        itemTypes: ['indivisaires'],
      });

      setValue('proprietaire.indivisaire.indivision.nom', indivision.nom, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue(
        'proprietaire.indivisaire.nature_indivisaire',
        proprietaire.indivisaire.nature_indivisaire,
        {
          shouldDirty: true,
          shouldValidate: true,
        }
      );
    }
  }, [proprietaire]);

  useEffect(() => {
    if (!edition || contratsProprietaires) {
      return;
    }

    fetchFilteredItems({
      filter: { item_type: 'diagnostic' },
      itemTypes: ['contrats_proprietaires'],
    });
  }, [contratsProprietaires]);

  useEffect(() => {
    if (indivisaireReferentSelectWatch) {
      setValue(
        'contrat_proprietaire.referent_technique',
        indivisaireReferentSelectWatch.proprietaire.contact,
        { shouldValidate: true }
      );
      setReferentIsIndivisaire(true);
    } else {
      setReferentIsIndivisaire(false);
    }
  }, [indivisaireReferentSelectWatch]);

  useEffect(() => {
    if (!isIndivisaire && proprietaire?.indivisaire) {
      // Existing indivisaire
      setValue('proprietaire.indivisaire', null);
    } else if (isIndivisaire) {
      if (!proprietaire.indivisaire) {
        // New indivisaire
        setValue(
          'proprietaire.indivisaire.indivision.nom',
          `Indivision ${proprietaire.contact.nom}`,
          {
            shouldDirty: true,
            shouldValidate: true,
          }
        );
        dispatch(
          setFilteredItems({
            itemsList: [
              {
                proprietaire,
                principale: true,
                disabledEdit: true,
              },
            ],
            itemType: 'indivisaires',
          })
        );
      } else {
        setValue(
          'proprietaire.indivisaire.indivision.nom',
          proprietaire.indivisaire.indivision.nom,
          {
            shouldDirty: true,
            shouldValidate: true,
          }
        );
      }
    }
  }, [isIndivisaire]);

  useEffect(() => {
    if (isDifferentReferent) {
      const proprietaireIsReferent =
        getValues('contrat_proprietaire.referent_technique.id') ===
        proprietaire?.contact?.id;
      if (!edition || proprietaireIsReferent) {
        setValue('contrat_proprietaire.referent_technique', emptyContact, {
          shouldValidate: true,
        });
      }
    } else {
      setIndivisaireReferentSelectValue('indivisaire', null);
      setValue(
        'contrat_proprietaire.referent_technique',
        proprietaire?.contact
      );
      trigger('contrat_proprietaire.referent_technique.nom');
    }
  }, [isDifferentReferent]);

  return (
    <TabPanelForm currentValue={tabValue} index={0}>
      <form>
        {edition && !contratId && (
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6} md={3}>
              <ContratProprietaireAutocompleteField
                currentContratProprietaire={contratProprietaire}
                setContratProprietaire={setContratProprietaire}
                contratsProprietaires={contratsProprietaires}
              />
            </Grid>
          </Grid>
        )}
        {!contratId && (
          <Grid
            container
            spacing={2}
            {...(edition && { sx: { mt: 1 } })}
            alignItems='center'
          >
            {(!edition || (contratProprietaire && proprietaire)) && (
              <Grid item xs={12} sm={6} md={3}>
                <ProprietaireAutocompleteField
                  proprietaire={proprietaire}
                  setProprietaire={setProprietaire}
                  required
                />
              </Grid>
            )}
            {proprietaire && (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <BaseTextField
                    control={control}
                    name='proprietaire.contact.nom'
                    maxLength={256}
                    required='Nom requis.'
                    label='Nom'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <BaseTextField
                    control={control}
                    name='proprietaire.contact.prenom'
                    maxLength={256}
                    required='Prénom requis'
                    label='Prénom'
                  />
                </Grid>
              </>
            )}
          </Grid>
        )}

        {proprietaire && !contratId && (
          <>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
              <Grid item xs={12} sm={12} md={4}>
                <AdresseField
                  control={control}
                  fieldName={'proprietaire.contact.adresse'}
                  relatedFiedlNames={{
                    commune: 'proprietaire.contact.commune',
                    codePostal: 'proprietaire.contact.code_postal',
                    pays: 'proprietaire.contact.pays',
                  }}
                  label={'Adresse propriétaire'}
                  setValue={setValue}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BaseTextField
                  control={control}
                  name='proprietaire.contact.commune'
                  maxLength={256}
                  label='Commune propriétaire'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <CodePostalTextField
                  control={control}
                  name='proprietaire.contact.code_postal'
                  label='Code postal propriétaire'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DepartementField
                  label={'Département propriétaire'}
                  departements={choices?.departements}
                  control={control}
                  departementFieldName={'proprietaire.contact.departement'}
                  codePostalFieldName={'proprietaire.contact.code_postal'}
                  setValue={setValue}
                  required={!codePostalWatch && 'Département requis.'}
                  disabled={!!codePostalWatch}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
              <Grid item xs={12} sm={6} md={2}>
                <BaseTextField
                  control={control}
                  name='proprietaire.contact.telephone_fixe'
                  maxLength={16}
                  label='Téléphone fixe'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <BaseTextField
                  control={control}
                  name='proprietaire.contact.telephone_portable'
                  maxLength={16}
                  required={
                    requiredMapper.telephone_portable &&
                    'Téléphone portable requis.'
                  }
                  label='Téléphone portable'
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <MailTextField
                  control={control}
                  name='proprietaire.contact.email'
                  required={requiredMapper.email && 'E-mail requis.'}
                  maxLength={120}
                  label='E-mail'
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
              <Grid item xs={12} sm={6} md={3}>
                <SelectField
                  control={control}
                  name='proprietaire.personnalite_juridique'
                  label='Personnalité juridique'
                  baseId='personnalite'
                  renderValue={(value) => value.nom}
                  values={choices?.personnalites_juridiques}
                  itemMapper={(item) => ({
                    key: item.code,
                    label: item.nom,
                  })}
                  onClear={() =>
                    setValue('proprietaire.personnalite_juridique', null, {
                      shouldValidate: true,
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
              {personnaliteJuridique === 'PM' && (
                <>
                  <Grid item xs={12} sm={6} md={2}>
                    <BaseTextField
                      control={control}
                      name='proprietaire.raison_sociale'
                      maxLength={256}
                      label='Raison sociale'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <BaseTextField
                      control={control}
                      name='proprietaire.siret'
                      fixedLength={{
                        value: 14,
                        message: 'Le SIRET doit avoir 14 caractères.',
                      }}
                      label='SIRET'
                      pattern={{
                        value: /[0-9]{14}/i,
                        message: 'Le SIRET doit avoir 14 caractères.',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <AdresseField
                      control={control}
                      fieldName={'proprietaire.adresse_siege'}
                      relatedFiedlNames={{
                        commune: 'proprietaire.commune_siege',
                        codePostal: 'proprietaire.code_postal_siege',
                      }}
                      label={'Adresse siège'}
                      setValue={setValue}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <CodePostalTextField
                      control={control}
                      name='proprietaire.code_postal_siege'
                      label='Code postal siège'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <BaseTextField
                      control={control}
                      name='proprietaire.commune_siege'
                      maxLength={256}
                      label='Commune siège'
                    />
                  </Grid>
                </>
              )}
              {personnaliteJuridique === 'PP' && (
                <Grid item xs={12} sm={6} md={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isIndivisaire}
                        onChange={(e) => setIsIndivisaire(e.target.checked)}
                      />
                    }
                    label='Indivisaire'
                  />
                </Grid>
              )}
              {isIndivisaire && personnaliteJuridique === 'PP' && (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <SelectField
                      control={control}
                      name='proprietaire.indivisaire.nature_indivisaire'
                      baseId='nature-indivisaire'
                      label='Nature indivisaire'
                      renderValue={(value) => value.nom}
                      values={choices?.natures_indivisaires}
                      itemMapper={(indivisaire) => ({
                        key: indivisaire.id,
                        label: indivisaire.nom,
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <BaseTextField
                      control={control}
                      name='proprietaire.indivisaire.indivision.nom'
                      maxLength={256}
                      required='Indivision requise.'
                      label='Indivision'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <IndivisaireTable
                      items={indivisairesList}
                      control={control}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
              <Grid item xs={12} sm={6} md={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isDifferentReferent}
                      onChange={(e) => setIsDifferentReferent(e.target.checked)}
                    />
                  }
                  label='Référent technique contractuel différent du propriétaire'
                />
              </Grid>
              {isDifferentReferent && (
                <>
                  {isIndivisaire && personnaliteJuridique === 'PP' && (
                    <Grid item xs={12} sm={6} md={3}>
                      <IndivisaireReferentSelect
                        control={indivisaireReferentControl}
                        name='indivisaire'
                        indivisaires={indivisairesList}
                        onClear={clearIndivisaireReferent}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={3}>
                    <UpperCaseTextField
                      control={control}
                      name='contrat_proprietaire.referent_technique.nom'
                      maxLength={256}
                      required='Nom requis'
                      disabled={referentIsIndivisaire}
                      label='Nom'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <BaseTextField
                      control={control}
                      name='contrat_proprietaire.referent_technique.prenom'
                      maxLength={256}
                      disabled={referentIsIndivisaire}
                      label='Prénom'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <BaseTextField
                      control={control}
                      name='contrat_proprietaire.referent_technique.telephone_fixe'
                      maxLength={16}
                      disabled={referentIsIndivisaire}
                      label='Téléphone fixe'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <BaseTextField
                      control={control}
                      name='contrat_proprietaire.referent_technique.telephone_portable'
                      maxLength={16}
                      disabled={referentIsIndivisaire}
                      label='Téléphone portable'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <MailTextField
                      control={control}
                      name='contrat_proprietaire.referent_technique.email'
                      maxLength={120}
                      disabled={referentIsIndivisaire}
                      label={'E-mail'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <AdresseField
                      control={control}
                      fieldName={
                        'contrat_proprietaire.referent_technique.adresse'
                      }
                      relatedFiedlNames={{
                        commune:
                          'contrat_proprietaire.referent_technique.commune',
                        codePostal:
                          'contrat_proprietaire.referent_technique.code_postal',
                      }}
                      label={'Adresse'}
                      setValue={setValue}
                      disabled={referentIsIndivisaire}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <BaseTextField
                      control={control}
                      name='contrat_proprietaire.referent_technique.commune'
                      maxLength={256}
                      disabled={referentIsIndivisaire}
                      label='Commune'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <CodePostalTextField
                      control={control}
                      name='contrat_proprietaire.referent_technique.code_postal'
                      disabled={referentIsIndivisaire}
                      label='Code Postal'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DepartementField
                      label={'Département'}
                      departements={choices?.departements}
                      control={control}
                      departementFieldName={
                        'contrat_proprietaire.referent_technique.departement'
                      }
                      codePostalFieldName={
                        'contrat_proprietaire.referent_technique.code_postal'
                      }
                      setValue={setValue}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
              <Grid item xs={12} sm={6} md={3}>
                <SelectField
                  control={control}
                  required='Origine commerciale requise.'
                  name='proprietaire.origine_commerciale'
                  label='Origine commerciale'
                  baseId='origine'
                  values={choices?.origines_commerciales_types}
                  itemMapper={(origine) => ({
                    key: origine.id,
                    label: origine.nom,
                  })}
                  renderValue={(origine) => origine.nom}
                />
              </Grid>
            </Grid>
          </>
        )}
        {contratId && contratProprietaire && (
          <>
            <Grid container item spacing={2} mt={1}>
              <Grid item xs={12}>
                <ProprietaireTable items={proprietairesList} />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <DividerTitle text='Référent technique' />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ReferentTechniqueFormDialog
                  item={referentTechniqueWatch}
                  onSubmit={(value) =>
                    setValue('contrat_proprietaire.referent_technique', value)
                  }
                  proprietairesContactIds={
                    proprietairesList?.map((item) => item.contact.id)
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
      </form>
    </TabPanelForm>
  );
}

RenseignementProprietaireTab.propTypes = {
  edition: PropTypes.bool,
  tabValue: PropTypes.number,
  isIndivisaireState: PropTypes.object.isRequired,
  contratProprietaireState: PropTypes.object.isRequired,
  proprietaireState: PropTypes.object.isRequired,
  isDifferentReferentState: PropTypes.object.isRequired,
  referentIsIndivisaireState: PropTypes.object.isRequired,
  clearIndivisaireReferent: PropTypes.func.isRequired,
  formIndivisaireReferent: PropTypes.object.isRequired,
  emptyContact: PropTypes.object.isRequired,
  proprietaireFields: PropTypes.array.isRequired,
  contratId: PropTypes.string,
  form: PropTypes.object,
  choices: PropTypes.object,
  proprietairesList: PropTypes.array,
};
