import React from 'react';

import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import useFormItem from '@/hooks/useFormItem';

import AdresseField from '@/features/shared/AdresseField';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import FormSubmitButtons from '@/features/shared/FormSubmitButtons';

export default function EntrepriseForm({ item }) {
  const itemType = 'entreprises';

  const { onCancel, onSubmit } = useFormItem(item.id, itemType);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: item,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        {item.id ? 'Édition' : 'Création'} entreprise
      </Typography>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'nom'}
              control={control}
              rules={{ maxLength: 200, required: 'Nom requis.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Nom'}
                  inputProps={{ maxLength: 200 }}
                  fullWidth
                  required={true}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={7} md={5}>
            <AdresseField
              control={control}
              fieldName={'adresse'}
              relatedFiedlNames={{
                commune: 'commune',
                codePostal: 'code_postal',
              }}
              label={'Adresse'}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'commune'}
              control={control}
              rules={{ maxLength: 256 }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Commune'}
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'code_postal'}
              control={control}
              rules={{
                maxLength: {
                  value: 5,
                  message: 'Le code postal doit avoir 5 caractères.',
                },
                minLength: {
                  value: 5,
                  message: 'Le code postal doit avoir 5 caractères.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Code postal'}
                  inputProps={{
                    maxLength: 5,
                    pattern: '[0-9]*',
                  }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'telephone'}
              control={control}
              rules={{
                maxLength: 16,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Téléphone'}
                  inputProps={{
                    maxLength: 16,
                  }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'siret'}
              control={control}
              rules={{
                maxLength: {
                  value: 14,
                  message: 'Le SIRET doit avoir 14 caractères.',
                },
                minLength: {
                  value: 14,
                  message: 'Le SIRET doit avoir 14 caractères.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'SIRET'}
                  inputProps={{
                    maxLength: 14,
                    pattern: '[0-9]*',
                  }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'emploi_insertion'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Emploi insertion'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'fonction'}
              control={control}
              rules={{ maxLength: 120 }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Fonction'}
                  inputProps={{ maxLength: 120 }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
        <FormSubmitButtons
          onSubmit={handleSubmit(onSubmit)}
          submitLabel={item.id ? 'Éditer' : 'Créer'}
          disabledSubmit={!isDirty || !isValid}
          onCancel={onCancel}
        />
      </form>
    </Box>
  );
}

EntrepriseForm.propTypes = {
  item: PropTypes.object.isRequired,
};
