import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import CellExpand from '@/components/CellExpand';

import CRUDDataGrid from '@/components/CRUDDataGrid';

import Typography from '@mui/material/Typography';

import ParcelleCadastralePressentieSousContratFormDialog from './ParcelleCadastralePressentieSousContratFormDialog';

export default function ParcelleCadastralePressentieSousContratTable({
  items,
  title,
  disabledEdit,
}) {
  const columns = [
    {
      field: 'prefixe',
      headerName: 'Préfixe',
      width: 150,
      editable: false,
    },
    {
      field: 'section',
      headerName: 'Section',
      width: 150,
      editable: false,
    },
    {
      field: 'numero',
      headerName: 'Numéro',
      width: 150,
      editable: false,
    },
    {
      field: 'contenance',
      headerName: 'Surface cadastrale (ha)',
      width: 170,
      type: 'number',
      editable: false,
      valueFormatter: (value) => {
        if (typeof value === 'string') {
          value = Number(value);
        }
        return value ? value.toFixed(4) : null;
      },
    },
    {
      field: 'nom_commune',
      headerName: 'Nom commune',
      width: 200,
      editable: false,
      renderCell: ({ row }) => <CellExpand value={row.nom_commune} />,
    },
    {
      field: 'code_commune',
      headerName: 'Code commune',
      width: 150,
      editable: false,
    },
  ];

  const [totalArea, setTotalArea] = useState(0);

  useEffect(() => {
    if (!items) {
      return;
    }

    const total = items
      .filter((p) => p.contenance)
      .reduce((acc, val) => acc + parseFloat(val.contenance), 0);

    const roundedTotal = (Math.round(total * 10000) / 10000).toFixed(4);

    setTotalArea(roundedTotal);
  }, [items]);

  const onDuplicate = (rowToDuplicate) => {
    return { ...rowToDuplicate, id: null, rowId: null };
  };

  return (
    <>
      <CRUDDataGrid
        itemsList={items}
        columns={columns}
        itemType={'parcelles_cadastrales_pressenties_sous_contrat'}
        addItemLabel={'Ajouter une parcelle'}
        title={title}
        modal={{ component: ParcelleCadastralePressentieSousContratFormDialog }}
        onDuplicate={onDuplicate}
        disabledEdit={disabledEdit}
      />
      <Typography
        mt={1}
        variant='subtitle2'
        component='div'
        sx={{ color: 'primary.light' }}
      >
        Surface cadastrale totale : {totalArea} ha
      </Typography>
    </>
  );
}

ParcelleCadastralePressentieSousContratTable.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  disabledEdit: PropTypes.bool,
};
