import { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Custom hook to manage tab state and update the URL with the tab value.
 *
 * @param {number} defaultTab - The default tab value.
 * @returns {[number, function]} - The current tab value and the function to handle tab change.
 */
export default function useTabState(defaultTab = 0) {
  const location = useLocation();
  const [tabValue, setTabValue] = useState(parseInt(defaultTab, 10) || 0);

  /**
   * Handles the tab change event by updating the URL with the new tab value
   * and sets the new tab value in the state.
   *
   * @param {Object} _ - The event object (not used).
   * @param {number} newValue - The new tab value.
   */
  const handleTabChange = useCallback(
    (_, newValue) => {
      // Create a URLSearchParams object from the current location's search string
      const searchParams = new URLSearchParams(location.search);

      // Set the new tab value in the search parameters
      searchParams.set('tab', newValue);

      // Construct the new URL with the updated search parameters
      const newUrl = `${location.pathname}?${searchParams.toString()}`;

      // Update the URL in the browser's history without reloading the page
      window.history.replaceState(null, '', newUrl);

      // Update the state with the new tab value
      setTabValue(newValue);
    },
    [location]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab');
    if (tab !== null) {
      setTabValue(Number(tab));
    }
  }, [location.search]);

  return [tabValue, handleTabChange];
}
