import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import useOnMount from '@/hooks/useOnMount';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

export default function CellExpand({ value, editIcon }) {
  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const isOverflown = (element) =>
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth;

  const showCell = () => setShowFullCell(true);

  const hideCell = () => setShowFullCell(false);

  useOnMount(() => {
    if (cellDiv.current) {
      setAnchorEl(cellDiv.current);
    }
  });

  useOnMount(() => {
    if (cellValue && cellValue.current) {
      const isCurrentlyOverflown = isOverflown(cellValue?.current);
      setShowPopper(isCurrentlyOverflown);
    }
  });

  return (
    <div
      ref={wrapper}
      onMouseEnter={showCell}
      onMouseLeave={hideCell}
      style={{
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
      }}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width: '100%',
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <div
        ref={cellValue}
        style={{
          whitespace: 'nowwrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </div>
      {editIcon}
      {showPopper && (
        <Popper open={showFullCell && anchorEl !== null} anchorEl={anchorEl}>
          <Box elevation={3} component={Paper} sx={{ width: '100%', p: 1 }}>
            {value}
          </Box>
        </Popper>
      )}
    </div>
  );
}

CellExpand.propTypes = {
  value: PropTypes.node,
  editIcon: PropTypes.node,
};
