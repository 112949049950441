import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { useGridApiRef } from '@mui/x-data-grid';

import DatePickerField from '@/components/controlled-fields/DatePickerField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import NumberTextField from '@/components/controlled-fields/text-fields/NumberTextField';
import BaseDataGrid from '@/components/BaseDataGrid';

import DocumentFormDialog from '@/features/shared/document/DocumentFormDialog';

import { setFilteredItems } from '@/slices/itemSlice';

import { fromISODate } from '@/utils/date';

const DEFAULT_VALUES = {
  code_chantier: null,
  date_signature_contrat_seing_prive: null,
  montant_forfaitaire: null,
  montant_total_previsionnel_operations: null,
  pourcentage_services_environnementaux: null,
};

export default function SignatureContratFormDialog({
  openState,
  setEtatContrat,
  codesChantierList,
  itemState,
  documentsList,
  unitesGestionList,
  setDateSignatureSeingPrive,
}) {
  const [open, setOpen] = openState;
  const [openDocument, setOpenDocument] = useState(false);
  const [item, setItem] = itemState;
  const [document, setDocument] = useState(null);

  const userId = useSelector((store) => store.persistAuthReducer.user.id);
  const dispatch = useDispatch();

  useEffect(() => {
    if (item) {
      setDateSignatureSeingPrive(item.date_signature_contrat_seing_prive);
    }
  }, [item]);

  const {
    control,
    getValues,
    reset,
    formState: { isValid },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    reValidateMode: 'onBlur',
    mode: 'onChange',
    values: item,
  });

  const apiRef = useGridApiRef();

  const columns = [
    {
      field: 'nom',
      headerName: 'Nom',
      width: 300,
      editable: false,
    },
    {
      field: 'date_fin',
      headerName: 'Date de fin',
      width: 300,
      editable: true,
      type: 'date',
      valueFormatter: (value) => fromISODate(value),
      renderCell: ({ row }) => (
        <>
          {fromISODate(row.date_fin)}
          <IconButton
            color='primary'
            onClick={() =>
              apiRef.current.startCellEditMode({
                id: row.rowId,
                field: 'date_fin',
              })
            }
          >
            <EditIcon fontSize='small' />
          </IconButton>
        </>
      ),
    },
  ];

  const handleClose = () => {
    setOpen(false);
    reset(item);
  };

  const ugRows = useMemo(() => {
    return unitesGestionList?.map((ug, index) => ({
      ...ug,
      rowId: index + 1,
    }));
  }, [unitesGestionList]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <DialogTitle justifyContent='space-between'>
        <Grid container justifyContent='space-between'>
          <Grid item>Signature du contrat sous seing privé</Grid>
          <Grid item>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => setOpen(false)}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ p: 5 }}>
        <form>
          <Grid
            container
            sx={{ mt: 1 }}
            spacing={2}
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item xs={12} sm={6}>
              <Typography component='div' color='primary'>
                Code chantier validé
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <BaseTextField
                control={control}
                label='Code chantier'
                name='code_chantier'
                required='Code chantier requis.'
                validate={(codeChantier) => {
                  if (codesChantierList.includes(codeChantier)) {
                    return 'Ce code chantier existe déjà.';
                  }
                  return true;
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ mt: 1 }}
            spacing={2}
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item xs={12} sm={6}>
              <Typography component='div' color='primary'>
                Date de signature
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePickerField
                control={control}
                label='Date de signature du contrat sous seing privé'
                name='date_signature_contrat_seing_prive'
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ mt: 1 }}
            spacing={2}
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item xs={12} sm={6}>
              <Typography component='div' color='primary'>
                Montant forfaitaire
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberTextField
                control={control}
                label='€'
                name='montant_forfaitaire'
                decimal={2}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ mt: 1 }}
            spacing={2}
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item xs={12} sm={6}>
              <Typography component='div' color='primary'>
                Budget prévisionnel
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberTextField
                control={control}
                label='€'
                name='montant_total_previsionnel_operations'
                decimal={2}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ mt: 1 }}
            spacing={2}
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item xs={12} sm={6}>
              <Typography component='div' color='primary'>
                Répartition recette services environnementaux
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberTextField
                control={control}
                label='%'
                name='pourcentage_services_environnementaux'
                decimal={2}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ mt: 1 }}
            spacing={2}
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item xs={12}>
              <Typography component='div' color='primary'>
                Date de fin du contrat par UG
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <BaseDataGrid
                apiRef={apiRef}
                columns={columns}
                rows={ugRows}
                getRowId={(row) => row.rowId}
                processRowUpdate={(updatedRow) => {
                  dispatch(
                    setFilteredItems({
                      itemsList: ugRows.map((ug) => {
                        if (ug.rowId === updatedRow.rowId) {
                          return updatedRow;
                        }
                        return ug;
                      }),
                      itemType: 'unites_gestion',
                    })
                  );

                  return updatedRow;
                }}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 3 }} spacing={2} alignItems='center'>
            <Grid item>
              <Button onClick={() => setOpenDocument(true)} variant='contained'>
                Joindre le contrat
              </Button>
            </Grid>
            {document && (
              <Grid item>
                <Typography component='div'>
                  {document.repository
                    ? `${document.name} (${document.repository})`
                    : `${document.name} (${document.filename})`}
                </Typography>
              </Grid>
            )}

            <DocumentFormDialog
              open={openDocument}
              setOpen={setOpenDocument}
              onSubmit={(value) => {
                setDocument(value);
                setOpenDocument(false);
              }}
              enableUpload
              userId={userId}
            />
          </Grid>
          <Grid container sx={{ mt: 2 }} spacing={2} alignItems='center'>
            <Grid item>
              <Typography component='div'>
                {
                  "En validant, l'avancement du contrat passera automatiquement au stade « contrat signé ». En réinitialisant, vous dévalider la signature du contrat et faites disparaitre toutes les informations saisies."
                }
              </Typography>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button
          onClick={() => {
            setItem({
              ...DEFAULT_VALUES,
              code_chantier: item.code_chantier,
            });
            setEtatContrat('GESTIONNAIRE');
            dispatch(
              setFilteredItems({
                itemsList: unitesGestionList.map((ug) => ({
                  ...ug,
                  date_fin: null,
                })),
                itemType: 'unites_gestion',
              })
            );
            setOpen(false);
          }}
        >
          Réinitialiser
        </Button>
        <Button
          onClick={() => {
            setItem(getValues());
            setEtatContrat('SIGNE');
            if (document) {
              dispatch(
                setFilteredItems({
                  itemsList: documentsList.concat([document]),
                  itemType: 'documents',
                })
              );
              setDocument(null);
            }
            setOpen(false);
          }}
          variant='contained'
          disabled={!isValid}
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SignatureContratFormDialog.propTypes = {
  openState: PropTypes.array.isRequired,
  setEtatContrat: PropTypes.func.isRequired,
  codesChantierList: PropTypes.array,
  itemState: PropTypes.array.isRequired,
  documentsList: PropTypes.array,
  unitesGestionList: PropTypes.array,
  setDateSignatureSeingPrive: PropTypes.func.isRequired,
};
