import React from 'react';

import PropTypes from 'prop-types';

import DgdFormDialog from '@/features/fiche-propriete/dgd/DgdFormDialog';
import CRUDDataGrid from '@/components/CRUDDataGrid';

import { fromISODate } from '@/utils/date';

export default function DgdTable({ dgdLists }) {
  const columns = [
    {
      headerName: 'Type',
      field: 'type',
      width: 220,
      editable: false,
      valueGetter: (type) => type.nom,
    },
    {
      headerName: 'Numéro',
      field: 'numero',
      width: 150,
      editable: false,
    },
    {
      headerName: 'Surface',
      field: 'surface_totale',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      width: 150,
      editable: false,
    },
    {
      headerName: 'Date de début',
      field: 'date_debut',
      width: 150,
      editable: false,
      valueGetter: (date) => fromISODate(date),
    },
    {
      headerName: 'Date de fin',
      field: 'date_fin',
      width: 150,
      editable: false,
      valueGetter: (date) => fromISODate(date),
    },
  ];
  return (
    <CRUDDataGrid
      itemsList={dgdLists}
      columns={columns}
      itemType='dgd'
      addItemLabel='Ajouter un DGD'
      modal={{ component: DgdFormDialog }}
    />
  );
}

DgdTable.propTypes = {
  dgdLists: PropTypes.array,
};
