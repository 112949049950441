import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import useFormItem from '@/hooks/useFormItem';
import useItems from '@/hooks/useItems';

import SelectField from '@/components/controlled-fields/SelectField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import FormSubmitButtons from '@/features/shared/FormSubmitButtons';

export default function DepartementCmfForm({ item }) {
  const itemType = 'departements_cmf';

  const { onCancel, onSubmit } = useFormItem(item.id, itemType);

  const usersList = useItems('users');

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: item,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        Édition département CMF
      </Typography>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={4} md={3}>
            <BaseTextField
              control={control}
              name='numero'
              disabled
              label='N° dpt'
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <BaseTextField
              control={control}
              name='nom'
              disabled
              label='Département'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SelectField
              control={control}
              name='user'
              required='CMF requis'
              label='CMF'
              renderValue={(value) => value.username}
              values={
                usersList &&
                usersList.filter((user) => user.role.name === 'neosylva_cmf')
              }
              itemMapper={(user) => ({
                key: user.id,
                label: user.username,
              })}
            />
          </Grid>
        </Grid>
        <FormSubmitButtons
          onSubmit={handleSubmit(onSubmit)}
          submitLabel='Éditer'
          disabledSubmit={!isDirty || !isValid}
          onCancel={onCancel}
        />
      </form>
    </Box>
  );
}

DepartementCmfForm.propTypes = {
  item: PropTypes.object.isRequired,
};
