import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import SwitchField from '@/components/controlled-fields/SwitchField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import TabPanelForm from '@/components/TabPanelForm';

import StationTable from '@/features/shared/station/StationTable';

import Grid from '@mui/material/Grid';

export default function StationForestiereTab({
  tabValue,
  form,
  stationsList,
}) {

  const { control } = form;

  const catalogueStationWatch = useWatch({
    control,
    name: 'propriete.catalogue_station',
  });

  return (
    <TabPanelForm currentValue={tabValue} index={4}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={2}>
            <SwitchField
              control={control}
              name='propriete.catalogue_station'
              label='Catalogue de station'
            />
          </Grid>
          {catalogueStationWatch && (
            <Grid item xs={12} sm={12} md={4}>
              <MultilineTextField
                control={control}
                name='propriete.catalogue_station_detail'
                label='Catalogue de station référence'
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <StationTable items={stationsList} catalogueStation={catalogueStationWatch} />
        </Grid>
      </form>
    </TabPanelForm>
  );
}

StationForestiereTab.propTypes = {
  tabValue: PropTypes.number,
  form: PropTypes.object,
  stationsList: PropTypes.array,
};