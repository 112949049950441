import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import SignatureActeNotarieFormDialog from './SignatureActeNotarieFormDialog';

export default function SignatureActeNotarie({
  setEtatContrat,
  itemState,
  documentsList,
  setDateSignatureActeNotarie,
}) {

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant='contained'
        fullWidth
      >
        Signature acte notarié
      </Button>
      <SignatureActeNotarieFormDialog
        openState={[open, setOpen]}
        setEtatContrat={setEtatContrat}
        itemState={itemState}
        documentsList={documentsList}
        setDateSignatureActeNotarie={setDateSignatureActeNotarie}
      />
    </>
  );
}

SignatureActeNotarie.propTypes = {
  setEtatContrat: PropTypes.func.isRequired,
  itemState: PropTypes.array.isRequired,
  documentsList: PropTypes.array,
  setDateSignatureActeNotarie: PropTypes.func.isRequired,
};