import React from 'react';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import ManyItemsCell from '@/components/ManyItemsCell';

import CRUDDataGrid from '@/components/CRUDDataGrid';

import StationFormDialog from './StationFormDialog';

export default function StationTable({ items, catalogueStation }) {
  const columns = [
    {
      field: 'numero',
      headerName: 'Numéro',
      width: 140,
      editable: false,
    },
    {
      field: 'code',
      headerName: 'Code du catalogue de station ou description',
      width: 240,
      editable: false,
    },
    {
      field: 'facteurs_limitants',
      headerName: 'Facteurs limitants',
      width: 200,
      editable: false,
    },
    {
      field: 'essences_principales_adaptees',
      headerName: 'Essences principales adaptées',
      width: 260,
      valueGetter: (value) => value.map((essence) => essence.nom_vernaculaire),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.rowId}
          relatedItems={{
            relatedItemsList: row.essences_principales_adaptees,
            getRelatedName: (essence) => essence.nom_vernaculaire,
            relatedVerboseName: 'essence',
          }}
        />
      ),
    },
    {
      field: 'essences_secondaires_adaptees',
      headerName: 'Essences secondaires adaptées',
      width: 260,
      valueGetter: (value) => value.map((essence) => essence.nom_vernaculaire),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.rowId}
          relatedItems={{
            relatedItemsList: row.essences_secondaires_adaptees,
            getRelatedName: (essence) => essence.nom_vernaculaire,
            relatedVerboseName: 'essence',
          }}
        />
      ),
    },
    {
      field: 'exposition',
      headerName: 'Exposition',
      width: 200,
      editable: false,
      valueGetter: (value) => value.nom,
    },
    {
      field: 'topographie',
      headerName: 'Topographie',
      width: 200,
      editable: false,
      valueGetter: (value) => value.nom,
    },
  ];

  const onDuplicate = (rowToDuplicate) => {
    return {
      ...rowToDuplicate,
      numero: `${rowToDuplicate.numero} (copie)`,
      temp_uuid: uuidv4(),
      id: null,
      rowId: null,
    };
  };

  return (
    <CRUDDataGrid
      itemsList={items}
      columns={columns}
      itemType={'stations'}
      title={'Stations forestières'}
      addItemLabel={'Ajouter une station'}
      modal={{ component: StationFormDialog, props: { catalogueStation } }}
      onDuplicate={onDuplicate}
    />
  );
}

StationTable.propTypes = {
  items: PropTypes.array,
  catalogueStation: PropTypes.bool.isRequired,
};
