import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';
import useFormItem from '@/hooks/useFormItem';

import AutocompleteAPI from '@/components/AutoCompleteAPI';
import DatePickerField from '@/components/controlled-fields/DatePickerField';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import APIService from '@/APIService';
import FormSubmitButtons from '@/features/shared/FormSubmitButtons';

export default function InterventionForm({ item }) {
  const itemType = 'interventions';

  const choices = useChoices(itemType);

  const { onCancel, onSubmit } = useFormItem(item.id, itemType);

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: item,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  const proprieteForm = useForm({
    defaultValues: { propriete: null },
  });

  const [propriete, setPropriete] = useState(item.unite_gestion?.propriete);

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        {item.id ? 'Édition' : 'Création'} intervention
      </Typography>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'type'}
              control={control}
              rules={{
                required: 'Type requis.',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth required={true}>
                  <InputLabel id='type-select-label'>Type</InputLabel>
                  <Select
                    labelId='type-select-label'
                    id='type-select'
                    onChange={onChange}
                    value={value || ''}
                    label='Type'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choices &&
                      choices.types.map((item) => (
                        <MenuItem key={item.id} value={item}>
                          ({item.code}) {item.nom}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'etat'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='etat-select-label'>État</InputLabel>
                  <Select
                    labelId='etat-select-label'
                    id='etat-select'
                    onChange={onChange}
                    value={value || ''}
                    label='État'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choices &&
                      choices.etats.map((etat) => (
                        <MenuItem key={etat.id} value={etat}>
                          {etat.nom}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'annee_prevue'}
              control={control}
              rules={{
                required: 'Année prévue requise',
                minLength: 4,
                maxLength: 4,
                pattern: {
                  value: /^\d+$/,
                  message: 'Année prévue invalide.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Année prévue'}
                  inputProps={{
                    maxLength: 4,
                    minLength: 4,
                    pattern: '[0-9]*',
                  }}
                  fullWidth
                  required={true}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePickerField
              name='date_effective'
              control={control}
              label='Date effective'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'commentaire'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Commentaire'}
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'surface'}
              control={control}
              rules={{
                pattern: {
                  value: /^\d+(\.\d{0,4})?$/,
                  message: 'Surface invalide.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Surface (ha)'}
                  type={'number'}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onBlur={(e) => {
                    let val = parseFloat(e.target.value);
                    if (val) {
                      val = val.toFixed(2);
                    }
                    onChange(val);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'propriete'}
              control={proprieteForm.control}
              rules={{ required: 'Propriété requise.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AutocompleteAPI
                  getItemDescription={(item) => item.nom_commun || ''}
                  label={'Propriété'}
                  searchFunc={(searchTerm) =>
                    APIService.get({
                      url: 'proprietes/search/',
                      opts: { params: { name: searchTerm } },
                    })
                  }
                  onChange={(val) => {
                    setPropriete(val);
                    return onChange(val);
                  }}
                  error={error}
                  value={value}
                  required={true}
                  defaultValue={item.unite_gestion?.propriete}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'unite_gestion'}
              control={control}
              rules={{ required: 'Unité de gestion requise.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AutocompleteAPI
                  onChange={onChange}
                  error={error}
                  value={value}
                  disabled={!propriete}
                  getItemDescription={(item) => item.nom || ''}
                  label={'Unité de gestion'}
                  searchFunc={(searchTerm) =>
                    APIService.get({
                      url: `unites_gestion/${propriete.id}/search/`,
                      opts: { params: { name: searchTerm } },
                    })
                  }
                  required={true}
                  defaultValue={item.unite_gestion}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'qualite_commercialisee'}
              control={control}
              rules={{ maxLength: 120 }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Qualité commercialisée'}
                  inputProps={{ maxLength: 120 }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'quantite_commercialisee_m3_stere'}
              control={control}
              rules={{
                pattern: {
                  value: /^\d+(\.\d{0,2})?$/,
                  message: 'Quantité invalide.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Quantité'}
                  type={'number'}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onBlur={(e) => {
                    let val = parseFloat(e.target.value);
                    if (val) {
                      val = val.toFixed(2);
                    }
                    onChange(val);
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <FormSubmitButtons
          onSubmit={handleSubmit(onSubmit)}
          submitLabel={item.id ? 'Éditer' : 'Créer'}
          disabledSubmit={!isDirty || !isValid}
          onCancel={onCancel}
        />
      </form>
    </Box>
  );
}

InterventionForm.propTypes = {
  item: PropTypes.object.isRequired,
};
