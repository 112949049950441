import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { format as formatDate } from 'date-fns';

import { DateField } from '@mui/x-date-pickers';

export default function EditDateFieldCell({ id, field, value, api }) {
  const handleChange = useCallback(
    (value) => {
      api.setEditCellValue({
        id,
        field,
        value:
          String(value) === 'Invalid Date'
            ? null
            : formatDate(value, 'yyyy-MM-dd'),
      });
    },
    [api, id, field]
  );

  return (
    <DateField
      defaultValue={value ? new Date(value) : null}
      onChange={handleChange}
      variant='standard'
      sx={{
        justifyContent: 'center',
        paddingLeft: 1,
      }}
      autoFocus
      InputProps={{
        disableUnderline: true,
      }}
    />
  );
}

EditDateFieldCell.propTypes = {
  id: PropTypes.number.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.object,
  api: PropTypes.object.isRequired,
};
