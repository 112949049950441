import React from 'react';

import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function ContratProprietaireAutocompleteField({
  currentContratProprietaire,
  setContratProprietaire,
  contratsProprietaires,
}) {
  const { control } = useForm({
    reValidateMode: 'onBlur',
    mode: 'onChange',
    defaultValues: {
      code_chantier: currentContratProprietaire,
    },
  });

  return (
    <Controller
      name={'code_chantier'}
      control={control}
      rules={{ required: 'Code chantier temporaire requis.' }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          required={true}
          onChange={(_, item) => {
            setContratProprietaire(item);
            return onChange(item);
          }}
          getOptionLabel={(item) => item?.code_chantier || ''}
          options={contratsProprietaires || []}
          loading={!contratsProprietaires}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              inputProps={{
                ...params.inputProps,
                value:
                  params.inputProps.value === '' && value
                    ? value.code_chantier
                    : params.inputProps.value,
              }}
              label='Code chantier temporaire *'
              error={!!error}
              value={value || ''}
              helperText={error ? error.message : null}
            />
          )}
        />
      )}
    />
  );
}

ContratProprietaireAutocompleteField.propTypes = {
  currentContratProprietaire: PropTypes.object,
  setContratProprietaire: PropTypes.func.isRequired,
  contratsProprietaires: PropTypes.array,
};
