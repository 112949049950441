import React, { useState, useMemo, useCallback } from 'react';

import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import EditDateFieldCell from '@/components/EditDateFieldCell';

export default function BaseDataGrid({
  rows,
  columns,
  sx,
  alternateRowColor,
  headerClassName,
  autoHeight = true,
  defaultPagination = true,
  boxProps = {},
  ...props
}) {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const onPaginationModelChange = useCallback(
    (updatedPaginationModel) => {
      if (!defaultPagination) {
        return;
      }
      setPaginationModel(updatedPaginationModel);
    },
    [defaultPagination]
  );

  const columnsList = useMemo(() => {
    return columns.map((column) => {
      column.headerClassName = headerClassName;

      if (!column.editable) {
        return column;
      }
      switch (column.type) {
        case 'date':
          return {
            ...column,
            renderEditCell: (params) => <EditDateFieldCell {...params} />,
          };
        default:
          return column;
      }
    });
  }, [columns, headerClassName]);

  return (
    <Box {...boxProps}>
      <DataGrid
        loading={!rows}
        autoHeight={autoHeight}
        rows={rows ? rows : []}
        columns={columnsList}
        {...(defaultPagination && {
          paginationModel,
          onPaginationModelChange,
        })}
        pageSizeOptions={[10, 15, 20, 30, 50, 100]}
        checkboxSelection={false}
        sx={{
          ...sx,
          '.datagrid-odd-row': {
            backgroundColor: '#ffffff',
          },
          '.datagrid-even-row': {
            backgroundColor: '#f8f8f8',
          },
        }}
        getRowClassName={
          alternateRowColor &&
          (({ id }) =>
            id % 2 === 0 ? 'datagrid-even-row' : 'datagrid-odd-row')
        }
        {...props}
      />
    </Box>
  );
}

BaseDataGrid.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  sx: PropTypes.object,
  autoHeight: PropTypes.bool,
  alternateRowColor: PropTypes.bool,
  apiRef: PropTypes.object,
  boxProps: PropTypes.object,
  getRowId: PropTypes.func,
  defaultPagination: PropTypes.bool,
  headerClassName: PropTypes.string,
};
