import React from 'react';

import PropTypes from 'prop-types';

import CertificationForestiereFormDialog from './CertificationForestiereFormDialog';
import CRUDDataGrid from '@/components/CRUDDataGrid';

import { fromISODate } from '@/utils/date';

export default function CertificationForestiereTable({
  certificationsList,
}) {

  const columns = [
    {
      headerName: 'Type',
      field: 'type',
      width: 300,
      editable: false,
      valueGetter: (type) => type.nom,
    },
    {
      headerName: 'N° adhérent',
      field: 'numero_adherent',
      width: 300,
      editable: false,
    },
    {
      headerName: 'Date de fin',
      field: 'date_fin',
      width: 150,
      editable: false,
      valueGetter: (date) => fromISODate(date)
    },
  ];

  return (
    <CRUDDataGrid
      itemsList={certificationsList}
      columns={columns}
      itemType='certifications'
      addItemLabel='Ajouter une certification'
      modal={{ component: CertificationForestiereFormDialog }}
    />
  );
}

CertificationForestiereTable.propTypes = {
  certificationsList: PropTypes.array,
};