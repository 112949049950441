import React from 'react';

import PropTypes from 'prop-types';

import CellExpand from '@/components/CellExpand';
import ManyItemsCell from '@/components/ManyItemsCell';

import CRUDDataGrid from '@/components/CRUDDataGrid';
import UniteGestionFormDialog from '@/features/shared/unite-gestion/UniteGestionFormDialog';

export default function UniteGestionTable({ items, stationsList }) {
  const columns = [
    {
      field: 'nom',
      headerName: 'Nom',
      width: 180,
      editable: false,
    },
    {
      field: 'surface_sig_ug',
      headerName: 'Surface unité de gestion (ha)',
      width: 160,
      editable: false,
      valueFormatter: (value) => {
        if (typeof value === 'string') {
          value = Number(value);
        }
        return value ? value.toFixed(4) : value;
      },
    },
    {
      field: 'itineraire_sylvicole_preconise',
      headerName: 'Itinéraire sylvicole préconisé',
      width: 220,
      editable: false,
      renderCell: ({ row }) => (
        <CellExpand value={row.itineraire_sylvicole_preconise?.nom} />
      ),
    },
    {
      field: 'densite',
      headerName: 'Densité',
      width: 160,
      editable: false,
    },
    {
      field: 'type_peuplement_existant',
      headerName: 'Peuplement existant',
      width: 200,
      editable: false,
      valueGetter: (_, row) => row.peuplement_initial?.type?.nom,
    },
    {
      field: 'essences_principales_existantes',
      headerName: 'Essences principales existantes',
      width: 220,
      editable: false,
      valueGetter: (value) =>
        value?.essences_list
          .filter((value) => value.type === 'Principale')
          .map((value) => value.essence.nom_vernaculaire),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.rowId}
          relatedItems={{
            relatedItemsList:
              row.peuplement_initial?.essences_list.filter(
                (value) => value.type === 'Principale'
              ) || [],
            getRelatedName: (value) => value.essence.nom_vernaculaire,
            relatedVerboseName: 'essence',
          }}
        />
      ),
    },
    {
      field: 'peuplement_objectif',
      headerName: 'Peuplement objectif',
      width: 220,
      editable: false,
      valueGetter: (value) => value?.nom,
    },
    {
      field: 'essences_principales_objectives',
      headerName: 'Essences principales objectives',
      width: 220,
      editable: false,
      valueGetter: (_, row) =>
        row.essences_list
          .filter((essence) => essence.type === 'Principale')
          .map((essence) => essence.nom_vernaculaire),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.rowId}
          relatedItems={{
            relatedItemsList: row.essences_list?.filter(
              (essence) => essence.type === 'Principale'
            ),
            getRelatedName: (essence_parcelle) =>
              essence_parcelle.essence.nom_vernaculaire,
            relatedVerboseName: 'essence',
          }}
        />
      ),
    },
    {
      field: 'station',
      headerName: 'Station',
      width: 160,
      editable: false,
      valueGetter: (value) => value?.numero,
    },
  ];

  const onDuplicate = (rowToDuplicate) => {
    const newUniteGestion = {
      ...rowToDuplicate,
      nom: `${rowToDuplicate.nom} (copie)`,
      id: null,
      rowId: null,
    };

    const cloneObject = (object) => {
      return { ...object, id: null };
    };

    newUniteGestion.essences_list =
      newUniteGestion.essences_list?.map(cloneObject);
    newUniteGestion.interventions_list =
      newUniteGestion.interventions_list?.map(cloneObject);
    newUniteGestion.peuplement_initial = cloneObject(
      newUniteGestion.peuplement_initial
    );
    newUniteGestion.peuplement_initial.essences_list =
      newUniteGestion.peuplement_initial.essences_list?.map(cloneObject);

    return newUniteGestion;
  };

  return (
    <CRUDDataGrid
      itemsList={items}
      columns={columns}
      itemType={'unites_gestion'}
      title={'Unités de gestion'}
      addItemLabel={'Ajouter une unité de gestion'}
      onDuplicate={onDuplicate}
      modal={{
        component: UniteGestionFormDialog,
        props: { stationsList },
      }}
    />
  );
}

UniteGestionTable.propTypes = {
  items: PropTypes.array,
  stationsList: PropTypes.array,
};
