import React from 'react';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmationDialog() {
  const {
    isOpened,
    confirm,
    decline,
    alternativeConfirm,
    content,
    title,
    confirmButton,
    declineButton,
    alternativeButton,
  } = useConfirmationDialog();

  return (
    <Dialog open={isOpened}>
      <DialogTitle> {title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ whiteSpace: 'pre-wrap' }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={decline}>{declineButton}</Button>
        <ButtonGroup variant='text'>
          <Button onClick={confirm}>{confirmButton}</Button>
          {alternativeButton && (
            <Button onClick={alternativeConfirm}>{alternativeButton}</Button>
          )}
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
