import React from 'react';

import PropTypes from 'prop-types';

import CheckboxSelectField from '@/components/controlled-fields/CheckboxSelectField';
import SelectField from '@/components/controlled-fields/SelectField';
import SwitchField from '@/components/controlled-fields/SwitchField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import TabPanelForm from '@/components/TabPanelForm';

import EspecePlanChasseTable from '@/features/shared/espece-plan-chasse/EspecePlanChasseTable';

import Grid from '@mui/material/Grid';

export default function EquilibreForetGibierTab({ tabValue, form, choices, especesPlanChasseList }) {

  const { control } = form;

  return (
    <TabPanelForm currentValue={tabValue} index={3}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid container item>
            <Grid item xs={12} sm={6} md={3}>
              <SwitchField
                control={control}
                name='propriete.acca'
                label='ACCA'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SwitchField
                control={control}
                name='propriete.societe_chasse'
                label='Société de chasse'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SwitchField
                control={control}
                name='propriete.reservataire'
                label='Le propriétaire est réservataire (Alsace-Moselle)'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SwitchField
                control={control}
                name='propriete.plan_chasse'
                label="Existence d'un plan de chasse"
              />
            </Grid>
          </Grid>
          <Grid container item sx={{ mt: 1 }} spacing={2}>
            <Grid container item xs={12} md={6}>
              <Grid item xs={12}>
                <SelectField
                  control={control}
                  name='propriete.degats_constates'
                  baseId='degats-constates'
                  label='Dégâts constatés'
                  renderValue={(value) => value.nom}
                  values={choices?.degats_constates_types}
                  itemMapper={(degat) => ({
                    key: degat.id,
                    label: degat.nom,
                  })}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <CheckboxSelectField
                  control={control}
                  name='propriete.natures_degats_list'
                  baseId='degat-nature'
                  label='Natures dégâts constatés'
                  values={choices?.natures_degats_types}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <MultilineTextField
                control={control}
                name='propriete.commentaire_chasse_pression_gibier'
                label='Pression gibier constatée'
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2} alignItems='center'>
            <Grid item xs={12} md={6}>
              <EspecePlanChasseTable items={especesPlanChasseList} />
            </Grid>
            <Grid item xs={12} md={6}>
              <BaseTextField
                control={control}
                name='propriete.identite_detenteur_droit_chasse'
                maxLength={256}
                label='Identité du détenteur du droit de chasse'
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

EquilibreForetGibierTab.propTypes = {
  tabValue: PropTypes.number,
  form: PropTypes.object.isRequired,
  choices: PropTypes.object,
  especesPlanChasseList: PropTypes.array,
};