import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import SwitchField from '@/components/controlled-fields/SwitchField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import TabPanelForm from '@/components/TabPanelForm';

import StationTable from '@/features/shared/station/StationTable';

import { setFilteredItems } from '@/slices/itemSlice';

import Grid from '@mui/material/Grid';

export default function StationsForestieresTab({
  form,
  tabValue,
  propriete,
  stationsList,
  unitesGestionList,
}) {
  const { control } = form;
  const dispatch = useDispatch();

  useEffect(() => {
    const tempIdMapper = {};
    const tempIdList = [];

    stationsList?.forEach((station) => {
      if (station.id) {
        tempIdMapper[station.id] = station.temp_uuid;
      }
      tempIdList.push(station.temp_uuid);
    });

    const updatedUnitesGestionList = unitesGestionList?.map((uniteGestion) => {
      const updatedUniteGestion = { ...uniteGestion };
      const stationId = updatedUniteGestion.station?.id;

      if (stationId && !updatedUniteGestion.temp_uuid) {
        updatedUniteGestion.temp_uuid = tempIdMapper[stationId];
      } else if (!tempIdList.includes(updatedUniteGestion.temp_uuid)) {
        updatedUniteGestion.temp_uuid = null;
        updatedUniteGestion.station = null;
      }

      return updatedUniteGestion;
    });

    dispatch(
      setFilteredItems({
        itemType: 'unites_gestion',
        itemsList: updatedUnitesGestionList,
      })
    );
  }, [stationsList?.length]);

  const catalogueStation = useWatch({
    control,
    name: 'propriete.catalogue_station',
  });

  return (
    <TabPanelForm currentValue={tabValue} index={3}>
      {propriete && (
        <form>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6} md={2}>
              <SwitchField
                control={control}
                name='propriete.catalogue_station'
                label='Catalogue de station'
              />
            </Grid>
            {catalogueStation && (
              <Grid item xs={12} sm={12} md={4}>
                <MultilineTextField
                  control={control}
                  name='propriete.catalogue_station_detail'
                  label='Catalogue de station référence'
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <StationTable items={stationsList} catalogueStation={catalogueStation}/>
            </Grid>
          </Grid>
        </form>
      )}
    </TabPanelForm>
  );
}

StationsForestieresTab.propTypes = {
  form: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
  propriete: PropTypes.object,
  stationsList: PropTypes.array,
  unitesGestionList: PropTypes.array,
};
