import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import useDownloadDocument from '@/hooks/useDownloadDocument';
import useIsGestionnaire from '@/hooks/useIsGestionnaire';

import CRUDDataGrid from '@/components/CRUDDataGrid';

import { setMessage } from '@/slices/messageSlice';

import { fromISODateTime } from '@/utils/date';
import { downloadFromUrl } from '@/utils/file';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';

import APIService from '@/APIService';
import DocumentFormDialog from './DocumentFormDialog';

export default function DocumentTable({
  items,
  title,
  contratProprietaire,
  disableFileEdit,
  enableUpload,
}) {
  const downloadDocument = useDownloadDocument();
  const isGestionnaire = useIsGestionnaire();
  const userId = useSelector((store) => store.persistAuthReducer.user.id);

  const isSharepoint = (item) => !!item.repository;

  const dispatch = useDispatch();

  const handleOpenDocument = (row) => {
    if (!contratProprietaire) {
      return;
    }

    if (isSharepoint(row)) {
      window.open(row.repository, '_blank');
    } else if (row.id) {
      APIService.get({
        url: `contrats_proprietaires/${contratProprietaire.id}/files/${row.id}`,
        opts: { responseType: 'blob' },
        onError: async ({ data }) => {
          const message = JSON.parse(await data.text()).detail;
          if (message) {
            dispatch(setMessage(message));
          }
        },
      }).then(downloadDocument);
    } else {
      downloadFromUrl(row.local_url, row.filename);
    }
  };
  const columns = [
    {
      field: 'name',
      headerName: 'Nom',
      width: 150,
      editable: false,
    },
    {
      field: 'comment',
      headerName: 'Commentaire',
      width: 250,
      editable: false,
    },
    {
      field: 'repository',
      headerName: 'Lien Sharepoint / Fichier',
      width: 250,
      editable: false,
      renderCell: ({ row }) => (
        <IconButton
          size='small'
          aria-label='close'
          color='inherit'
          title={row.repository}
          onClick={() => handleOpenDocument(row)}
        >
          <OpenInNewIcon color='primary' />
        </IconButton>
      ),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  return (
    <CRUDDataGrid
      itemsList={
        items?.map((item) => ({
          ...item,
          disabledEdit:
            disableFileEdit || (isGestionnaire && item.user_id !== userId),
        })) || []
      }
      columns={columns}
      itemType={'documents'}
      addItemLabel={'Ajouter un document'}
      title={title}
      modal={{
        component: DocumentFormDialog,
        props: {
          userId,
          enableUpload,
        },
      }}
    />
  );
}

DocumentTable.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  contratProprietaire: PropTypes.object,
  disableFileEdit: PropTypes.bool,
  enableUpload: PropTypes.bool,
};
