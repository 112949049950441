import React, { useMemo } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import Container from '@mui/material/Container';

import useItem from '@/hooks/useItem';

import FicheProprieteForm from '@/features/fiche-propriete/FicheProprieteForm';


export default function FichePropriete() {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;
  const defaultTab = useMemo(() => searchParams.get('tab'));

  const emptyItem = {
    id: null,
    nom_commun: null,
    creation_datetime: null,
    last_modification_datetime: null,
    adresse: null,
    commune: null,
    code_postal: null,
    cmf_neosylva: null,
    adhesion_syndicat: null,
    adhesion_syndicat_detail: null,
    departement: null,
    surface_totale_propriete: null,
    existence_dgd: false,
    objectifs_gestion_list: [],
    historique_gestion: null,
    certification_forestiere: false,
    acces_massif: null,
    acces_grumier: null,
    nb_place_depot: null,
    besoin_acces_supplementaire: null,
    description_ressource_forestiere: null,
    description_filiere_bois: null,
    charges: null,
    ressources: null,
    equipement_defense_incendie: null,
    enjeux_economiques_gestion_forestiere: null,
    frequentation_public: false,
    mesure_accueil_public: false,
    mesure_reduction_frequentation: false,
    balisage_particulier: false,
    convention_ouverture_publique: false,
    importance_frequentation: null,
    impact_frequentation_gestion: null,
    acca: false,
    societe_chasse: false,
    reservataire: false,
    plan_chasse: false,
    degats_constates: null,
    natures_degats_list: [],
    commentaire_chasse_pression_gibier: null,
    identite_detenteur_droit_chasse: null,
    catalogue_station: false,
    catalogue_station_detail: null,
    zonages_identifies_list: [],
    zonages_identifies_commentaire: null,
    gestionnaire_preexistant: false,
    droits_usage_servitudes_divers: null,
    certifications_list: [],
    proprietaires_list: [],
    contrats_proprietaires_list: [],
    dgd_list: [],
    elements_biodiversite_list: [],
    stations_list: [],
    especes_plan_chasse_list: [],
    acces_ouvrages_list: [],
    gestionnaire: null,
    contrat_proprietaires_list: [],
  };

  const item = useItem(itemId, 'proprietes', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <FicheProprieteForm item={item} defaultTab={defaultTab} />}
    </Container>
  );
}
