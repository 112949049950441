import React from 'react';

import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';
import useFormItem from '@/hooks/useFormItem';

import AutocompleteAPI from '@/components/AutoCompleteAPI';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import APIService from '@/APIService';
import FormSubmitButtons from '@/features/shared/FormSubmitButtons';

export default function StationForm({ item }) {
  const itemType = 'stations';

  const choices = useChoices(itemType);


  const { onCancel, onSubmit } = useFormItem(item.id, itemType);

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: item,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        {item.id ? 'Édition' : 'Création'} station
      </Typography>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'numero'}
              control={control}
              rules={{ maxLength: 120, required: 'Numéro requis.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Numéro'}
                  inputProps={{ maxLength: 120 }}
                  fullWidth
                  required={true}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'code'}
              control={control}
              rules={{ maxLength: 256 }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Code du catalogue de station ou description'}
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'exposition'}
              control={control}
              rules={{ required: 'Exposition requise.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth required>
                  <InputLabel id='expo-select-label'>Exposition</InputLabel>
                  <Select
                    labelId='expo-select-label'
                    id='expo-select'
                    onChange={onChange}
                    value={value || ''}
                    label='Exposition'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choices &&
                      choices.expositions.map((item) => (
                        <MenuItem key={item.id} value={item}>
                          {item.nom}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'topographie'}
              control={control}
              rules={{ required: 'Topographie requise.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth required>
                  <InputLabel id='topographie-select-label'>
                    Topographie
                  </InputLabel>
                  <Select
                    labelId='topographie-select-label'
                    id='topographie-select'
                    onChange={onChange}
                    value={value || ''}
                    label='Topographie'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choices &&
                      choices.topographies.map((item) => (
                        <MenuItem key={item.id} value={item}>
                          {item.nom}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'facteurs_limitants'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Facteurs limitants'}
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'essences_principales_adaptees'}
              control={control}
              rules={{ required: 'Essences principales adaptées requises.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  loading={!choices}
                  disablePortal
                  multiple
                  required
                  onChange={(_, item) => onChange(item)}
                  getOptionLabel={(p) => (p ? p.nom_vernaculaire : '')}
                  options={choices ? choices.essences : []}
                  defaultValue={item.essences_principales_adaptees}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label='Essences principales adaptées *'
                      error={!!error}
                      value={value ? value : ''}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'essences_secondaires_adaptees'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  loading={!choices}
                  disablePortal
                  multiple
                  onChange={(_, item) => onChange(item)}
                  getOptionLabel={(p) => (p ? p.nom_vernaculaire : '')}
                  options={choices ? choices.essences : []}
                  defaultValue={item.essences_secondaires_adaptees}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label='Essences secondaires adaptées'
                      error={!!error}
                      value={value ? value : ''}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'propriete'}
              control={control}
              rules={{ required: 'Propriété requise.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AutocompleteAPI
                  onChange={onChange}
                  error={error}
                  value={value}
                  getItemDescription={(item) => item.nom_commun || ''}
                  label={'Propriété'}
                  searchFunc={(searchTerm) =>
                    APIService.get({
                      url: 'proprietes/search/',
                      opts: { params: { name: searchTerm } },
                    })
                  }
                  required={true}
                  defaultValue={item.propriete}
                />
              )}
            />
          </Grid>
        </Grid>
        <FormSubmitButtons
          onSubmit={handleSubmit(onSubmit)}
          submitLabel={item.id ? 'Éditer' : 'Créer'}
          disabledSubmit={!isDirty || !isValid}
          onCancel={onCancel}
        />
      </form>
    </Box>
  );
}

StationForm.propTypes = {
  item: PropTypes.object.isRequired,
};
