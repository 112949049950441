import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useChoices from '@/hooks/useChoices';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import CellExpand from '@/components/CellExpand';
import StatefulDataGrid from '@/components/StatefulDataGrid';
import ManyItemsCell from '@/components/ManyItemsCell';
import XLSXExportButton from '@/components/XLSXExportButton';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODate, fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function GestionnairesPro() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'gestionnaires_pro';

  const contactChoices = useChoices('contacts');

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/gestionnaire-pro/${itemId}`);
  };

  const handleDeleteItem = async (itemId, itemName) => {
    const result = await open({
      title: 'Suppression',
      content: `Confirmer la suppression de ${itemName} ?`,
    });

    if (result === 'confirm') {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(params.id, params.row.contact.nom)}
        />,
      ],
    },
    {
      field: 'civilite',
      headerName: 'Civilité',
      type: 'singleSelect',
      valueOptions: ['Madame', 'Monsieur'],
      width: 120,
      editable: false,
      valueGetter: (_, row) => row.contact.civilite,
    },
    {
      field: 'nom',
      valueGetter: (_, row) => row.contact.nom,
      headerName: 'Nom',
      width: 120,
      editable: false,
      renderCell: ({ row }) => <CellExpand value={row.contact.nom} />,
    },
    {
      field: 'prenom',
      valueGetter: (_, row) => row.contact.prenom,
      headerName: 'Prénom',
      width: 120,
      editable: false,
    },
    {
      field: 'profession',
      valueGetter: (_, row) => row.contact.profession,
      headerName: 'Profession',
      width: 140,
      editable: false,
    },
    {
      field: 'relation_contractuelle_neosylva',
      valueGetter: (_, row) => row.contact.relation_contractuelle_neosylva,
      headerName: 'Relation contractuelle avec Néosylva',
      width: 140,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'contact_neosylva',
      headerName: 'Contact Néosylva',
      width: 160,
      editable: false,
      valueGetter: (_, row) => row.contact.contact_neosylva?.username,
    },
    {
      field: 'adresse',
      valueGetter: (_, row) => row.contact.adresse,
      headerName: 'Adresse',
      width: 200,
      editable: false,
    },
    {
      field: 'commune',
      valueGetter: (_, row) => row.contact.commune,
      headerName: 'Commune',
      width: 160,
      editable: false,
    },
    {
      field: 'code_postal',
      valueGetter: (_, row) => row.contact.code_postal,
      headerName: 'Code postal',
      width: 100,
      editable: false,
    },
    {
      field: 'departement',
      valueGetter: (_, row) => row.contact.departement,
      type: 'singleSelect',
      valueOptions: contactChoices?.departements,
      headerName: 'Département',
      width: 100,
      editable: false,
    },
    {
      field: 'pays',
      valueGetter: (_, row) => row.contact.pays,
      type: 'singleSelect',
      valueOptions: contactChoices?.pays,
      headerName: 'Pays',
      width: 100,
      editable: false,
    },
    {
      field: 'date_naissance',
      headerName: 'Date de naissance',
      width: 120,
      editable: false,
      valueFormatter: (value) => fromISODate(value),
      valueGetter: (_, row) => row.contact.date_naissance,
    },
    {
      field: 'email',
      valueGetter: (_, row) => row.contact.email,
      headerName: 'E-mail',
      width: 120,
      editable: false,
    },
    {
      field: 'telephone_fixe',
      valueGetter: (_, row) => row.contact.telephone_fixe,
      headerName: 'Téléphone fixe',
      width: 120,
      editable: false,
    },
    {
      field: 'telephone_portable',
      valueGetter: (_, row) => row.contact.telephone_portable,
      headerName: 'Téléphone portable',
      width: 120,
      editable: false,
    },
    {
      field: 'newsletter',
      valueGetter: (_, row) => row.contact.newsletter,
      headerName: 'Newsletter',
      width: 140,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'voeux',
      headerName: 'Voeux',
      type: 'singleSelect',
      valueOptions: contactChoices?.voeux_types.map((value) => value.nom),
      width: 140,
      editable: false,
      valueGetter: (_, row) => row.contact.voeux?.nom,
    },
    {
      field: 'rendez_vous_annuel_pressenti',
      valueGetter: (_, row) => row.contact.rendez_vous_annuel_pressenti,
      headerName: 'Rendez-vous annuel pressenti',
      width: 140,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'rendez_vous_annuels_list',
      headerName: 'Rendez-vous annuels',
      width: 260,
      valueGetter: (_, row) =>
        row.contact.rendez_vous_annuels_list.map(
          (rdv) => `${rdv.annee} - ${rdv.type.nom}`
        ),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.contact.rendez_vous_annuels_list,
            getRelatedName: (rdv) => `${rdv.annee} - ${rdv.type.nom}`,
            relatedVerboseName: 'Rendez-vous annuel',
          }}
        />
      ),
    },
    {
      field: 'commentaire',
      valueGetter: (_, row) => row.contact.commentaire,
      headerName: 'Commentaire',
      width: 200,
      editable: false,
    },
    {
      field: 'entreprises_list',
      headerName: 'Entreprises',
      width: 260,
      valueGetter: (_, row) =>
        row.contact.entreprises_list.map((entreprise) => entreprise.nom),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedPath: '/entreprise',
            relatedItemsList: row.contact.entreprises_list,
            getRelatedName: (entreprise) => entreprise.nom,
            relatedVerboseName: 'Entreprise',
          }}
        />
      ),
    },
    {
      field: 'decede_inactif',
      valueGetter: (_, row) => row.contact.decede_inactif,
      headerName: 'Décédé.e / non actif.ve',
      width: 200,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'most_recent_last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/nouveau-contact');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Gestionnaires professionnels
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter un gestionnaire professionnel
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <XLSXExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <StatefulDataGrid
        defaultPageSize={30}
        columns={columns}
        rows={items}
        boxSx={{ my: 2 }}
      />
    </Box>
  );
}
