import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import CheckboxSelectField from '@/components/controlled-fields/CheckboxSelectField';
import SelectField from '@/components/controlled-fields/SelectField';
import SwitchField from '@/components/controlled-fields/SwitchField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import NumberTextField from '@/components/controlled-fields/text-fields/NumberTextField';
import DividerTitle from '@/components/DividerTitle';
import TabPanelForm from '@/components/TabPanelForm';

import AccesOuvrageTable from '@/features/shared/acces-ouvrage/AccesOuvrageTable';
import ElementBiodiversiteTable from '@/features/shared/element-biodiversite/ElementBiodiversiteTable';

import Grid from '@mui/material/Grid';

export default function EnjeuxEcoSociauxEnvTab({
  tabValue,
  form,
  choices,
  accesOuvragesList,
  elementsBiodiversiteList,
}) {

  const { control } = form;

  const frequentationPublicWatch = useWatch({
    control,
    name: 'propriete.frequentation_public'
  });

  return (
    <TabPanelForm currentValue={tabValue} index={2}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <DividerTitle text='Enjeux économiques' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectField
              control={control}
              name='propriete.acces_massif'
              baseId='acces-massif'
              label='Accès massif'
              renderValue={(value) => value.nom}
              values={choices?.acces_massif_types}
              itemMapper={(acces) => ({
                key: acces.id,
                label: acces.nom,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectField
              control={control}
              name='propriete.acces_grumier'
              baseId='acces-grumier'
              label='Accès grumiers'
              renderValue={(value) => value.nom}
              values={choices?.acces_grumier_types}
              itemMapper={(acces) => ({
                key: acces.id,
                label: acces.nom,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumberTextField
              control={control}
              name='propriete.nb_place_depot'
              label='Nombre de places de dépôt'
              pattern={{
                value: /^\d+$/,
                message: 'Nombre de places de dépôt invalide.',
              }}
              decimal={0}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <MultilineTextField
              control={control}
              name='propriete.besoin_acces_supplementaire'
              label='Accessibilité et équipement de la forêt'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MultilineTextField
              control={control}
              name='propriete.description_ressource_forestiere'
              label='Description de la ressource forestière'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MultilineTextField
              control={control}
              name='propriete.description_filiere_bois'
              label='Description de filière bois'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MultilineTextField
              control={control}
              name='propriete.charges'
              label='Charges'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MultilineTextField
              control={control}
              name='propriete.ressources'
              label='Ressources'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MultilineTextField
              control={control}
              name='propriete.equipement_defense_incendie'
              label='Équipements pour la défense contre les incendies'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MultilineTextField
              control={control}
              name='propriete.enjeux_economiques_gestion_forestiere'
              label='Prise en compte des enjeux économiques dans la gestion'
            />
          </Grid>
          <Grid item xs={12}>
            <DividerTitle text='Accès et ouvrage de la propriété' />
          </Grid>
          <Grid item xs={12} md={8}>
            <AccesOuvrageTable items={accesOuvragesList} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <MultilineTextField
              control={control}
              name='propriete.droits_usage_servitudes_divers'
              label="Droits d'usage et servitudes diverses"
            />
          </Grid>
          <Grid item xs={12}>
            <DividerTitle text='Enjeux sociaux' />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <SwitchField
              control={control}
              name='propriete.frequentation_public'
              label='Fréquentation public'
            />
          </Grid>
          {frequentationPublicWatch &&
            <Grid item xs={12} sm={4}>
              <MultilineTextField
                control={control}
                name='propriete.importance_frequentation'
                label="Importance  et nature de la fréquentation"
              />
            </Grid>
          }
          <Grid item xs={12} sm={4} md={2}>
            <SwitchField
              control={control}
              name='propriete.mesure_accueil_public'
              label='Mesures pour accueillir et canaliser le public'
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <SwitchField
              control={control}
              name='propriete.mesure_reduction_frequentation'
              label='Mesures pour dissuader voire interdire la fréquentation'
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <SwitchField
              control={control}
              name='propriete.balisage_particulier'
              label="Existence d'un balisage particulier"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <SwitchField
              control={control}
              name='propriete.convention_ouverture_publique'
              label="Existence d'une convention d'ouverture au public"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MultilineTextField
              control={control}
              name='propriete.impact_frequentation_gestion'
              label='Prise en compte des enjeux sociaux dans la gestion forestière'
            />
          </Grid>
          <Grid item xs={12}>
            <DividerTitle text='Enjeux environnementaux' />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CheckboxSelectField
              control={control}
              name='propriete.zonages_identifies_list'
              baseId='zonage-constate'
              label='Zonages identifiés'
              values={choices?.zonages_identifies_types}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MultilineTextField
              control={control}
              name='propriete.zonages_identifies_commentaire'
              label='Autre(s) zonage(s)/ commentaires'
            />
          </Grid>
          <Grid item xs={12}>
            <DividerTitle text='Biodiversité' />
          </Grid>
          <Grid item xs={12} sm={8}>
            <ElementBiodiversiteTable items={elementsBiodiversiteList} />
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

EnjeuxEcoSociauxEnvTab.propTypes = {
  tabValue: PropTypes.number,
  form: PropTypes.object.isRequired,
  choices: PropTypes.object,
  accesOuvragesList: PropTypes.array,
  elementsBiodiversiteList: PropTypes.array,
};